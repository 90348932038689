import React, { useState } from "react";
import Form from 'react-bootstrap/Form';

import { usePostEmailMutation } from "../../redux/services/utilsv2";

import RightArrow from '../../assets/img/RightArrow.svg'
import Mail from '../../assets/img/Mail.svg'

const PopularData = ({ setOpen, setSuccessfullySent }) => {
    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(false);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleChange = event => {
        if (!isValidEmail(event.target.value)) {
            setValid(false);
        } else {
            setValid(true);
        }

        setEmail(event.target.value);
    };

    const [postEmail] = usePostEmailMutation()

    const handleSubmit = data => {
        data.preventDefault();
        if (valid) {
            postEmail(email).then((res) => {
                if ('error' in res) {
                    setSuccessfullySent(false)
                    setEmail("")
                    setOpen(true)
                    setTimeout(() => {
                        setOpen(false)
                    }, 3000)
                } else {
                    setEmail("")
                    setSuccessfullySent(true)
                    setOpen(true)
                    setTimeout(() => {
                        setOpen(false)
                    }, 3000)
                }
            })
        }
    };

    return (
        <div className="popular-data-block">
            <div className="conteiner">
                <h3 className="popular-data-title">Stay updated with popular data.</h3>
                <Form onSubmit={handleSubmit} className="popular-input-group">
                    <Form.Control
                        placeholder="Email Address"
                        className="popular-input"
                        type="text"
                        id="EmailInput"
                        name="postTitle"
                        value={email}
                        onChange={handleChange}
                    />
                    <img
                        alt="Mail"
                        src={Mail}
                        className="popular-data-input-img"
                    />
                    <button type="submit" className="popular-btn">
                        Submit
                        <img
                            alt="SearchBtn"
                            src={RightArrow}
                        />
                    </button>
                </Form>
            </div>
        </div>
    )
}

export default PopularData;