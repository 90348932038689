import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import ForenameJsx from './templates/pages/ForenameJsx';
import LastnameJsx from './templates/pages/LastnameJsx';

import { Routes, Route } from 'react-router-dom'
import Layout from './templates/Layout'
import ExploreJsx from './templates/pages/ExploreJsx';
import MainJsx from './templates/pages/MainJsx'
import Registration from './templates/pages/Registration';
import Login from './templates/pages/Login';
import SearchByPrefix from './templates/pages/SearchByPrefix';
import Names from './templates/pages/Names';
import PublicRecords from './templates/pages/PublicRecords';
import NamesByKeyword from './templates/pages/NamesByKeyword';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<MainJsx />} />
        <Route path='registration' element={<Registration />} />
        <Route path='login' element={<Login />} />
        <Route path='find_by_prefix/:forename' element={<SearchByPrefix />} />
        <Route path='firstname/:forename' element={<ForenameJsx />} />
        <Route path='lastname/:lastname' element={<LastnameJsx />} />
        <Route path='explore' element={<ExploreJsx />} />
        <Route path='public_records' element={<PublicRecords />} />
        <Route path='names' element={<Names />} />
        <Route path=':keyword' element={<NamesByKeyword />} />
      </Route>
    </Routes>
  );
}

export default App;