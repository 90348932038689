import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const firstnameApi = createApi({
    reducerPath: 'firstnameApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://family-tree-fastapi-prod-utpn6iuzbq-uc.a.run.app/api/' }),
    endpoints: (builder) => ({
        getFirstnameByPrefix: builder.query({
            query: (prefix) => ({
                url: `v1/identities/forenames/find_by_prefix?prefix=${prefix}&limit=20`,
                method: "GET"
            }),
        }),
        getFirstnameSimilar: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename-similar?forename=${firstname}`,
                method: "GET"
            }),
        }),
        getFirstnameDetails: builder.query({
            query: (firstname) => ({
                url: `v2/identities/forename?forename=${firstname}`,
                method: "GET"
            }),
        }),
        getFirstnameMostPopular: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename/${firstname}/rank`,
                method: "GET"
            }),
        }),
        getFirstnameDistribution: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forenames/${firstname}/countries_population`,
                method: "GET"
            }),
        }),
        getFirstnameHeight: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename/${firstname}/height`,
                method: "GET"
            }),
        }),
        getFirstnameRegilion: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename/${firstname}/religion`,
                method: "GET"
            }),
        }),
        getFirstnameSalary: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forenames/${firstname}/salary?start_task=false`,
                method: "GET"
            }),
        }),
        getFirstnamePolitics: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forename/${firstname}/politics`,
                method: "GET"
            }),
        }),
        getFirstnameYearlyPopulation: builder.query({
            query: (firstname) => ({
                url: `v1/identities/forenames/${firstname}/yearly_population`,
                method: "GET"
            }),
        }),
        getFirstnamePhoto: builder.query({
            query: (firstname) => ({
                url: `v2/utils/forename_photo?forename=${firstname}`,
                method: "GET"
            }),
        }),
        getFirstnamePopulations: builder.query({
            query: (firstname) => ({
                url: `v2/identities/forename_populations?name=${firstname}&limit=100&skip=0`,
                method: "GET"
            }),
        }),
        getFirstnamesMostPopular: builder.query({
            query: () => ({
                url: `v2/identities/most_popular?limit=10`,
                method: "GET"
            }),
        }),
        getFirstnamesMostPopularDay: builder.query({
            query: () => ({
                url: `v2/identities/forename_of_the_day`,
                method: "GET"
            }),
        }),
    })
})

export const {
    useGetFirstnameByPrefixQuery,
    useGetFirstnameSimilarQuery,
    useGetFirstnameDetailsQuery,
    useGetFirstnameMostPopularQuery,
    useGetFirstnameDistributionQuery,
    useGetFirstnameHeightQuery,
    useGetFirstnameRegilionQuery,
    useGetFirstnameSalaryQuery,
    useGetFirstnamePoliticsQuery,
    useGetFirstnameYearlyPopulationQuery,
    useGetFirstnamePhotoQuery,
    useGetFirstnamePopulationsQuery,
    useGetFirstnamesMostPopularQuery,
    useGetFirstnamesMostPopularDayQuery
} = firstnameApi