import DashboardFooter from "../components/DashboardFooter";
import HeaderJsx from "../components/HeaderJsx";

const PublicRecords = () => {
      
    return (
        <div>
            <HeaderJsx />
            
            
            <div className="height-full-size">
                PublicRecords
            </div>


            <DashboardFooter />
        </div>
    )
}

export default PublicRecords;