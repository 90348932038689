export const INTERNAL_LINKS = {
    "Afghanistan": "/afghan-names/",
    "Albania": "/albanian-names/",
    "Algeria": "/algerian-names/",
    "Andorra": "/andorran-names/",
    "Angola": "/angolan-names/",
    "Anguilla": "/anguillan-names/",
    "Argentina": "/argentine-names/",
    "Armenia": "/armenian-names/",
    "Australia": "/australian-names/",
    "Austria": "/austrian-names/",
    "Azerbaijan": "/azerbaijani-names/",
    "Bahamas": "/bahamian-names/",
    "Bahrain": "/bahraini-names/",
    "Bangladesh": "/bangladeshi-names/",
    "Barbados": "/barbadian-names/",
    "Belarus": "/belarusian-names/",
    "Belgium": "/belgian-names/",
    "Belize": "/belizean-names/",
    "Benin": "/beninese-names/",
    "Bermuda": "/bermudian-names/",
    "Bhutan": "/bhutanese-names/",
    "Bolivia": "/bolivian-names/",
    "Bosnia and Herzegovina": "/bosnian-names/",
    "Botswana": "/botswanan-names/",
    "Brazil": "/brazilian-names/",
    "Brunei": "/bruneian-names/",
    "Bukina Faso": "/burkinan-names/",
    "Bulgaria": "/bulgarian-names/",
    "Burundi": "/burundian-names/",
    "Cambodia": "/cambodian-names/",
    "Cameroon": "/cameroonian-names/",
    "Canada": "/canadian-names/",
    "Chad": "/chadian-names/",
    "Chile": "/chilean-names/",
    "China": "/chinese-names/",
    "Colombia": "/colombian-names/",
    "Comoros": "/comoran-names/",
    "Congo": "/congolese-names/",
    "Costa Rica": "/costa-rican-names/",
    "Croatia": "/croatian-names/",
    "Cuba": "/cuban-names/",
    "Cyprus": "/cypriot-names/",
    "Czech Republic": "/czech-names/",
    "Denmark": "/danish-names/",
    "Dominican": "/dominican-names/",
    "Ecuador": "/ecuadorean-names/",
    "Egypt": "/egyptian-names/",
    "El Salvador": "/salvadorean-names/",
    "England": "/english-names/",
    "Eritrea": "/eritrean-names/",
    "Estonia": "/estonian-names/",
    "Ethiopia": "/ethiopian-names/",
    "Faro Islands": "/faroese-names/",
    "Fiji": "/fijian-names/",
    "Finland": "/finnish-names/",
    "France": "/french-names/",
    "Gabon": "/gabonese-names/",
    "Gambia": "/gambian-names/",
    "Georgia": "/georgian-names/",
    "Germany": "/german-names/",
    "Ghana": "/ghanaian-names/",
    "Gibraltar": "/gibraltarian-names/",
    "Greece": "/greek-names/",
    "Greenland": "/greenlandic-names/",
    "Grenada": "/grenadian-names/",
    "Guatrmala": "/guatemalan-names/",
    "Guyana": "/guyanese-names/",
    "Haiti": "/haitian-names/",
    "Honduras": "/honduran-names/",
    "Hungary": "/hungarian-names/",
    "Iceland": "/icelandic-names/",
    "India": "/indian-names/",
    "Indonesia": "/indonesian-names/",
    "Iran": "/iranian-names/",
    "Iraq": "/iraqi-names/",
    "Ireland": "/irish-names/",
    "Israel": "/israeli-names/",
    "Italy": "/italian-names/",
    "Jamaica": "/jamaican-names/",
    "Japan": "/japanese-names/",
    "Jordan": "/jordanian-names/",
    "Kazakhstan": "/kazakh-names/",
    "Kenya": "/kenyan-names/",
    "Korea": "/korean-names/",
    "Kosovo": "/kosovan-names/",
    "Kuwait": "/kuwaiti-names/",
    "Kyrgyzstan": "/kyrgyz-names/",
    "Lao": "/lao-names/",
    "Latvia": "/latvian-names/",
    "Lebanon": "/lebanese-names/",
    "Liberia": "/liberian-names/",
    "Libya": "/libyan-names/",
    "Lithuania": "/lithuanian-names/",
    "Luxembourg": "/luxembourger-names/",
    "Macedonia": "/macedonian-names/",
    "Malawi": "/malawian-names/",
    "Malaysia": "/malaysian-names/",
    "Mali": "/malian-names/",
    "Malta": "/maltese-names/",
    "Maritious": "/mauritian-names/",
    "Mauritania": "/mauritanian-names/",
    "Mexico": "/mexican-names/",
    "Micronesia": "/micronesian-names/",
    "Moldova": "/moldovan-names/",
    "Mongolia": "/mongolian-names/",
    "Montenegro": "/montenegrin-names/",
    "Morocco": "/moroccan-names/",
    "Mozambique": "/mozambican-names/",
    "Myanmar": "/burmese-names/",
    "Nigerien": "/nigerien-names/",
    "Namibia": "/namibian-names/",
    "Nepal": "/nepalese-names/",
    "Netherlands": "/dutch-names/",
    "New Zealand": "/new-zealander-names/",
    "Nicaragua": "/nicaraguan-names/",
    "Nigeria": "/nigerian-names/",
    "Northern Ireland": "/northern-irish-names/",
    "Norway": "/norwegian-names/",
    "Oman": "/omani-names/",
    "Pakistan": "/pakistani-names/",
    "Palestine": "/palestinian-names/",
    "Panama": "/panamanian-names/",
    "Papua New Guinea": "/papua-new-guinean-names/",
    "Paraguay": "/paraguayan-names/",
    "Peru": "/peruvian-names/",
    "Philippines": "/filipino-names/",
    "Poland": "/polish-names/",
    "Portugal": "/portuguese-names/",
    "Puerto Rica": "/puerto-rican-names/",
    "Qatar": "/qatari-names/",
    "Romani": "/romanian-names/",
    "Russia": "/russian-names/",
    "Rwanda": "/rwandan-names/",
    "Samoa": "/samoan-names/",
    "Saudi Arabia": "/saudi-arabian-names/",
    "Scotland": "/scottish-names/",
    "Senegal": "/senegalese-names/",
    "Serbia": "/serbian-names/",
    "Seychelles": "/seychelles-names/",
    "Sierra Leon": "/sierra-leonean-names/",
    "Singapore": "/singaporean-names/",
    "Slovakia": "/slovak-names/",
    "Slovenia": "/slovenian-names/",
    "Solomon Islands": "/solomon-islander-names/",
    "Somalia": "/somali-names/",
    "South Africa": "/south-african-names/",
    "Spain": "/spanish-names/",
    "Sri Lanka": "/sri-lankan-names/",
    "Sudan": "/sudanese-names/",
    "Swaziland": "/swazi-names/",
    "Sweden": "/swedish-names/",
    "Switzerland": "/swiss-names/",
    "Syria": "/syrian-names/",
    "Taiwan": "/taiwanese-names/",
    "Tanzania": "/tanzanian-names/",
    "Thailand": "/thai-names/",
    "Togo": "/togolese-names/",
    "Tonga": "/tongan-names/",
    "Tunisia": "/tunisian-names/",
    "Turkey": "/turkish-names/",
    "Turkmenistan": "/turkmen-names/",
    "Tuvalu": "/tuvaluan-names/",
    "UAE": "/emirati-names/",
    "Uganda": "/ugandan-names/",
    "Ukraine": "/ukrainian-names/",
    "United Kingdom": "/british-names/",
    "United States of America": "/american-names/",
    "Uruguay": "/uruguayan-names/",
    "Uzbekistan": "/uzbek-names/",
    "Venezuela": "/venezuelan-names/",
    "Vietnam": "/vietnamese-names/",
    "Wales": "/welsh-names/",
    "Xook Islands": "/cook-names/",
    "Yemen": "/yemeni-names/",
    "Zambia": "/zambian-names/",
    "Zimbabwe": "/zimbabwean-names/",
    "Old English": "/old-english-names/",
    "Norman": "/norman-names/",
    "Occupational": "/occupational-names/",
    "Saxon": "/saxon-names/",
    "Byzantine": "/byzantine-names/",
    "Persia": "/persian-names/",
    "Roman": "/roman-names/",
    "Bible": "/biblical-names/",
    "Slavic": "/slavic-names/",
    "Latin": "/latin-names/",
    "Native-american": "/native-american-names/",
    "Arabic": "/arabic-names/",
    "Hebrew": "/hebrew-names/",
    "African": "/african-names/",
    "Scandinavian": "/scandinavian-names/",
    "Germanic": "/germanic-names/",
    "Mythology": "/mythological-names/",
    "Astronomy": "/astronomical-names/",
    "Medieval": "/medieval-names/",
    "Ancient": "/ancient-names/",
    "Modern": "/modern-names/",
    "Indigenous": "/indigenous-names/",
    "Nautical": "/nautical-names/",
    "Afghan": "/afghan-names/",
    "Albanian": "/albanian-names/",
    "Algerian": "/algerian-names/",
    "Andorran": "/andorran-names/",
    "Angolan": "/angolan-names/",
    "Anguillan": "/anguillan-names/",
    "Argentine": "/argentine-names/",
    "Armenian": "/armenian-names/",
    "Australian": "/australian-names/",
    "Austrian": "/austrian-names/",
    "Azerbaijani": "/azerbaijani-names/",
    "Bahamian": "/bahamian-names/",
    "Bahraini": "/bahraini-names/",
    "Bangladeshi": "/bangladeshi-names/",
    "Barbadian": "/barbadian-names/",
    "Belarusian": "/belarusian-names/",
    "Belgian": "/belgian-names/",
    "Belizean": "/belizean-names/",
    "Beninese": "/beninese-names/",
    "Bermudian": "/bermudian-names/",
    "Bhutanese": "/bhutanese-names/",
    "Bolivian": "/bolivian-names/",
    "Bosnian": "/bosnian-names/",
    "Botswanan": "/botswanan-names/",
    "Brazilian": "/brazilian-names/",
    "Bruneian": "/bruneian-names/",
    "Burkinan": "/burkinan-names/",
    "Bulgarian": "/bulgarian-names/",
    "Burundian": "/burundian-names/",
    "Cambodian": "/cambodian-names/",
    "Cameroonian": "/cameroonian-names/",
    "Canadian": "/canadian-names/",
    "Chadian": "/chadian-names/",
    "Chilean": "/chilean-names/",
    "Chinese": "/chinese-names/",
    "Colombian": "/colombian-names/",
    "Comoran": "/comoran-names/",
    "Congolese": "/congolese-names/",
    "Costa Rican": "/costa-rican-names/",
    "Croatian": "/croatian-names/",
    "Cuban": "/cuban-names/",
    "Cypriot": "/cypriot-names/",
    "Czech": "/czech-names/",
    "Danish": "/danish-names/",
    "Ecuadorean": "/ecuadorean-names/",
    "Egyptian": "/egyptian-names/",
    "Salvadorean": "/salvadorean-names/",
    "English": "/english-names/",
    "Eritrean": "/eritrean-names/",
    "Estonian": "/estonian-names/",
    "Ethiopian": "/ethiopian-names/",
    "Faroese": "/faroese-names/",
    "Fijian": "/fijian-names/",
    "Finnish": "/finnish-names/",
    "French": "/french-names/",
    "Gabonese": "/gabonese-names/",
    "Gambian": "/gambian-names/",
    "Georgian": "/georgian-names/",
    "German": "/german-names/",
    "Ghanaian": "/ghanaian-names/",
    "Gibraltarian": "/gibraltarian-names/",
    "Greek": "/greek-names/",
    "Greenlandic": "/greenlandic-names/",
    "Grenadian": "/grenadian-names/",
    "Guatemalan": "/guatemalan-names/",
    "Guyanese": "/guyanese-names/",
    "Haitian": "/haitian-names/",
    "Honduran": "/honduran-names/",
    "Hungarian": "/hungarian-names/",
    "Icelandic": "/icelandic-names/",
    "Indian": "/indian-names/",
    "Indonesian": "/indonesian-names/",
    "Iranian": "/iranian-names/",
    "Iraqi": "/iraqi-names/",
    "Irish": "/irish-names/",
    "Israeli": "/israeli-names/",
    "Italian": "/italian-names/",
    "Jamaican": "/jamaican-names/",
    "Japanese": "/japanese-names/",
    "Jordanian": "/jordanian-names/",
    "Kazakh": "/kazakh-names/",
    "Kenyan": "/kenyan-names/",
    "Korean": "/korean-names/",
    "Kosovan": "/kosovan-names/",
    "Kuwaiti": "/kuwaiti-names/",
    "Kyrgyz": "/kyrgyz-names/",
    "Latvian": "/latvian-names/",
    "Lebanese": "/lebanese-names/",
    "Liberian": "/liberian-names/",
    "Libyan": "/libyan-names/",
    "Lithuanian": "/lithuanian-names/",
    "Luxembourger": "/luxembourger-names/",
    "Macedonian": "/macedonian-names/",
    "Malawian": "/malawian-names/",
    "Malaysian": "/malaysian-names/",
    "Malian": "/malian-names/",
    "Maltese": "/maltese-names/",
    "Mauritian": "/mauritian-names/",
    "Mauritanian": "/mauritanian-names/",
    "Mexican": "/mexican-names/",
    "Micronesian": "/micronesian-names/",
    "Moldovan": "/moldovan-names/",
    "Mongolian": "/mongolian-names/",
    "Montenegrin": "/montenegrin-names/",
    "Moroccan": "/moroccan-names/",
    "Mozambican": "/mozambican-names/",
    "Burmese": "/burmese-names/",
    "Namibian": "/namibian-names/",
    "Nepalese": "/nepalese-names/",
    "Dutch": "/dutch-names/",
    "New Zealander": "/new-zealander-names/",
    "Nicaraguan": "/nicaraguan-names/",
    "Nigerian": "/nigerian-names/",
    "Northern Irish": "/northern-irish-names/",
    "Norwegian": "/norwegian-names/",
    "Omani": "/omani-names/",
    "Pakistani": "/pakistani-names/",
    "Palestinian": "/palestinian-names/",
    "Panamanian": "/panamanian-names/",
    "Papua New Guinean": "/papua-new-guinean-names/",
    "Paraguayan": "/paraguayan-names/",
    "Peruvian": "/peruvian-names/",
    "Filipino": "/filipino-names/",
    "Polish": "/polish-names/",
    "Portuguese": "/portuguese-names/",
    "Puerto Rican": "/puerto-rican-names/",
    "Qatari": "/qatari-names/",
    "Romanian": "/romanian-names/",
    "Russian": "/russian-names/",
    "Rwandan": "/rwandan-names/",
    "Samoan": "/samoan-names/",
    "Saudi Arabian": "/saudi-arabian-names/",
    "Scottish": "/scottish-names/",
    "Senegalese": "/senegalese-names/",
    "Serbian": "/serbian-names/",
    "Sierra Leonean": "/sierra-leonean-names/",
    "Singaporean": "/singaporean-names/",
    "Slovak": "/slovak-names/",
    "Slovenian": "/slovenian-names/",
    "Solomon Islander": "/solomon-islander-names/",
    "Somali": "/somali-names/",
    "South African": "/south-african-names/",
    "Spanish": "/spanish-names/",
    "Sri Lankan": "/sri-lankan-names/",
    "Sudanese": "/sudanese-names/",
    "Swazi": "/swazi-names/",
    "Swedish": "/swedish-names/",
    "Swiss": "/swiss-names/",
    "Syrian": "/syrian-names/",
    "Taiwanese": "/taiwanese-names/",
    "Tanzanian": "/tanzanian-names/",
    "Thai": "/thai-names/",
    "Togolese": "/togolese-names/",
    "Tongan": "/tongan-names/",
    "Tunisian": "/tunisian-names/",
    "Turkish": "/turkish-names/",
    "Turkmen": "/turkmen-names/",
    "Tuvaluan": "/tuvaluan-names/",
    "Emirati": "/emirati-names/",
    "Ugandan": "/ugandan-names/",
    "Ukrainian": "/ukrainian-names/",
    "British": "/british-names/",
    "American": "/american-names/",
    "Uruguayan": "/uruguayan-names/",
    "Uzbek": "/uzbek-names/",
    "Venezuelan": "/venezuelan-names/",
    "Vietnamese": "/vietnamese-names/",
    "Welsh": "/welsh-names/",
    "Cook": "/cook-names/",
    "Yemeni": "/yemeni-names/",
    "Zambian": "/zambian-names/",
    "Zimbabwean": "/zimbabwean-names/",
    "Persian": "/persian-names/",
    "Biblical": "/biblical-names/",
    "Native American": "/native-american-names/",
    "Mythological": "/mythological-names/",
    "Astronomical": "/astronomical-names/",
}