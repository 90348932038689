import React, { Component } from "react";
import LogoBlack from '../../assets/img/LogoBlack.svg';
import { Link } from "react-router-dom";
import Prefix from "./Prefix";

const DashboardFooter = () => {

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className="footer">
            <div className="footer-info">
                <div className="conteiner d-flex juctify-content-between align-items-start mobal-footer">
                    <div className="footer-info-component">
                        <div className="footer-logo-block footer-logo-block-white">
                            <img src={LogoBlack} alt="LogoIcon" className="me-2" />
                            OPR
                        </div>
                    </div>

                    <div className="footer-info-component">
                        <h3 className="footer-info-component-title footer-info-component-title-white">General</h3>
                        {window.location.pathname !== "/"
                            ?
                            <div className="mb-3 footer-info-component-text-div">
                                <a
                                    className="footer-info-component-text footer-info-component-text-white mb-3"
                                    href="https://ourpublicrecords.org/"
                                    target="_blank"
                                    rel="noreferrer"
                                >Home</a>
                            </div>
                            :
                            null
                        }
                        {window.location.pathname !== "/explore"
                            ?
                            <div className="mb-3 footer-info-component-text-div">
                                <a
                                    className="footer-info-component-text footer-info-component-text-white mb-3"
                                    href="https://ourpublicrecords.org/explore/"
                                    target="_blank"
                                    rel="noreferrer"
                                >Explore</a>
                            </div>
                            :
                            null
                        }
                        {/* {window.location.pathname !== "/names"
                            ?
                            <div className="footer-info-component-text-div">
                                <Link onClick={scrollToTop} to={`/names`} className="footer-info-component-text footer-info-component-text-white">Names</Link>
                            </div>
                            :
                            null
                        } */}
                        {/* <div className="footer-info-component-text-div">
                            <Link to={`/explore/All`} className="footer-info-component-text footer-info-component-text-white">Explore</Link>
                        </div> */}
                    </div>

                    <div className="footer-info-component-text-div d-flex flex-column">
                        {/*<h3 className="footer-info-component-title footer-info-component-title-white">Information</h3>*/}
                        <a
                            className="footer-info-component-text footer-info-component-text-white mb-3"
                            href="https://ourpublicrecords.org/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                        >Privacy Policy</a>
                        <a
                        className="footer-info-component-text footer-info-component-text-white mb-3"
                        href="https://ourpublicrecords.org/terms-of-service/"
                        target="_blank"
                        rel="noreferrer"
                    >Terms of Service</a>
                    </div>

                </div>
            </div>
            <Prefix />
            <div className="footer-social footer-social-white">
                <p>Copyright © 2023 OurPublicRecords.org All Rights Reserved</p>
            </div>
        </div>
    )
}

export default DashboardFooter;