import DashboardFooter from '../components/DashboardFooter';
import HeaderJsx from '../components/HeaderJsx';
import { Link, useParams } from "react-router-dom";
import LinkImg from '../../assets/img/Link.svg';
import Explore from '../../assets/img/Explore.svg';
import TotalNames from '../../assets/img/TotalNames.svg';
import GlobalCommonality from '../../assets/img/GlobalCommonality.svg';
import AverageHeight from '../../assets/img/AverageHeight.svg';
import AverageSalary from '../../assets/img/AverageSalary.svg';
import us from '../../assets/img/us.svg'
import { useState } from "react";
import ForenameNav from '../components/ForenameNav';
import { useGetFirstnameDetailsQuery, useGetFirstnameHeightQuery, useGetFirstnamePhotoQuery, useGetFirstnamePoliticsQuery, useGetFirstnamePopulationsQuery, useGetFirstnameRegilionQuery, useGetFirstnameSalaryQuery } from '../../redux/services/fitstname';
import Timeline from '../components/Timeline';
import Map from '../components/CountryContent/Map';
import ReactTooltip from 'react-tooltip';
import Tab from 'react-bootstrap/Tab';


const ForenameJsx = () => {
    let params = useParams();
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState("");
    const [contentPeople, setContentPeople] = useState("");
    const [contentRatio, setContentRatio] = useState("");
    const [contentRank, setContentRank] = useState("");
    const [contentFlag, setContentFlag] = useState("");

    const firstnameDetails = useGetFirstnameDetailsQuery(params.forename)
    const firstnamePhoto = useGetFirstnamePhotoQuery(params.forename)
    const firstnameSalary = useGetFirstnameSalaryQuery(params.forename)
    const firstnameHeight = useGetFirstnameHeightQuery(params.forename)
    const firstnamePolitics = useGetFirstnamePoliticsQuery(params.forename)
    const firstnameRegilion = useGetFirstnameRegilionQuery(params.forename)
    const firstnameDistribution = useGetFirstnamePopulationsQuery(params.forename)

    const dataPolitics = [];
    if (!firstnamePolitics.isLoading && !firstnamePolitics.isError) {
        for (let i = 0; i < firstnamePolitics.data.length; i++) {
            var politicsName = firstnamePolitics.data[i].name.charAt(4).toUpperCase() + firstnamePolitics.data[i].name.slice(5)
            var politicsField = { name: politicsName, value: firstnamePolitics.data[i].value }
            dataPolitics.push(politicsField)
        }
    }
    const dataReligion = [];
    if (!firstnameRegilion.isLoading && !firstnameRegilion.isError) {
        for (let i = 0; i < firstnameRegilion.data.length; i++) {
            var religionName = firstnameRegilion.data[i].name.charAt(4).toUpperCase() + firstnameRegilion.data[i].name.slice(5)
            var religionField = { name: religionName, value: firstnameRegilion.data[i].value }
            dataReligion.push(religionField)
        }
    }

    function numberWithCommas(x) {
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (!firstnameDetails.isLoading && !firstnameDetails.isError && firstnameDetails.data.forename_rank !== "") {
        var TotalNamesNumber = numberWithCommas(firstnameDetails.data.forename_rank.amount.toString())
        if (firstnameDetails.data.forename_rank.global_rank !== null) {
            var GlobalRank = numberWithCommas(firstnameDetails.data.forename_rank.global_rank.toString())
        } else {
            var GlobalRank = "-";
        }
        console.log("1")
        setTimeout(() => {
            var myElement = document.getElementById("descriptionTextID");
            var text = myElement.innerHTML;
            var newText = text.replace(/\."\s*/g, '". <br>');
            var newText2 = newText.replace(/\.([^\s])/g, ". <br>$1");
            myElement.innerHTML = newText2;
        }, 500)
    } else {
        var TotalNamesNumber = "-";
        var GlobalRank = "-";
    }

    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(window.location.toString());
        setTimeout(() => {
            setOpen(false)
        }, 2000)
    };
    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        null
    );

    const settings = {
        centerMode: true,
        infinite: true,
        centerPadding: "0px 0px 270px",
        slidesToShow: 2,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        prevArrow: <SlickArrowLeft />,
    };

    return (
        <div>
            {open
                ?
                <div className='clibboard' onClose={() => setOpen(false)} onClick={() => { setOpen(false) }}>
                    Copied to clibboard
                </div>
                : null
            }
            <HeaderJsx />
            <div className="height-full-size">
                <div className="search-header">
                    <div className='conteiner'>
                        <Link to={`/`}>Home</Link>
                        <span> / Search Results</span>
                        <span className="active"> / {params.forename}</span>
                    </div>
                </div>
                <div className="search-header py-4">
                    <div className='conteiner d-flex justify-content-between align-items-center'>
                        <h3 className='page-title'>{params.forename} <span className='span-status'>First Name</span></h3>
                        <div className='d-flex justify-content-end align-items-center'>

                            <div className='icon-block' onClick={handleClick}>
                                <img src={LinkImg} alt="" />
                            </div>

                            <Link className='m-0 explore-text' to={`/explore`}>
                                <img src={Explore} alt="" className='me-2' />
                                Explore Names
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='p-6 bg-grey'>
                    <div className='d-flex justify-content-between align-items-start conteiner'>
                        <ForenameNav />
                        <div className='main-content col-9'>

                            <div className='d-flex justify-content-around align-items-center' id="OverviewScroll">
                                {!firstnameDetails.isLoading && !firstnameDetails.isError
                                    ?
                                    <>
                                        <div className={'base-info-card' + (TotalNamesNumber == 0 ? ' d-none' : '')}>
                                            <img src={TotalNames} alt="TotalNames" className='mb-2' />
                                            <p className='base-info-title'>
                                                Total Records
                                                {/* <img src={InfoButton} alt="" className='ms-2' /> */}
                                            </p>
                                            <h3 className='base-info-value'>{TotalNamesNumber}</h3>
                                        </div>
                                        <div className='base-info-card'>
                                            <img src={GlobalCommonality} alt="TotalNames" className='mb-2' />
                                            <p className='base-info-title'>
                                                Global Commonality
                                                {/* <img src={InfoButton} alt="" className='ms-2' /> */}
                                            </p>
                                            <h3 className='base-info-value'>
                                                {GlobalRank}
                                                {firstnameDetails.data.forename_rank.global_rank % 10 === 1 && firstnameDetails.data.forename_rank.global_rank % 100 !== 11
                                                    ? (
                                                        <span>st</span>
                                                    )
                                                    : firstnameDetails.data.forename_rank.global_rank % 10 === 2 && firstnameDetails.data.forename_rank.global_rank % 100 !== 2 ? (
                                                        <span>nd</span>
                                                    )
                                                        : firstnameDetails.data.forename_rank.global_rank % 10 === 3 && firstnameDetails.data.forename_rank.global_rank % 100 !== 13 ? (
                                                            <span>rd</span>
                                                        ) :
                                                            <span>th</span>
                                                }
                                            </h3>
                                        </div>
                                        {firstnameDetails.data.forename_rank !== "" ?
                                            <div className='base-info-card'>
                                                <img src={firstnameDetails.data.forename_most_common_country_population.country.flag_link} alt="TotalNames" className='mb-2' />
                                                <p className='base-info-title'>
                                                    Most Records Found In
                                                    {/* <img src={InfoButton} alt="" className='ms-2' /> */}
                                                </p>
                                                <h3 className='base-info-value'>{firstnameDetails.data.forename_most_common_country_population.country.country}</h3>
                                            </div>
                                            :
                                            <div className='base-info-card'>
                                                <img src="" alt="TotalNames" className='mb-2' />
                                                <p className='base-info-title'>
                                                    Most Records Found In
                                                    {/* <img src={InfoButton} alt="" className='ms-2' /> */}
                                                </p>
                                                <h3 className='base-info-value'>-</h3>
                                            </div>
                                        }
                                    </>
                                    :
                                    null
                                }
                            </div>

                            {/* <div className='origin-block mt-4' id="OriginScroll">
                                    <h3 className='dashboard-title m-0'>Description</h3>
                                    <span className='dashboard-border'></span>
                                    {!firstnameDetails.isLoading && !firstnameDetails.isError && firstnameDetails.data.forename_details.description !=="" 
                                        ?
                                            <p className='origin-description' id="descriptionTextID">{firstnameDetails.data.forename_details.description}</p>
                                        :
                                            <p className='origin-description'>Currently, we don’t have information regarding the origin of this name</p>
                                    }
                                </div> */}

                            {!firstnameDistribution.isLoading && !firstnameDistribution.isError
                                ?
                                <>
                                    <div className='origin-block mt-4' id="DistributionScroll">
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="Map">
                                            <div className='d-flex justify-content-between align-items-start w-100'>
                                                <h3 className='dashboard-title m-0'>Distribution Of Worldwide Records</h3>
                                            </div>

                                            <Tab.Content className='w-100'>
                                                <Tab.Pane eventKey="Map">
                                                    <Map setTooltipContent={setContent} setTooltipContentPeple={setContentPeople} setTooltipcontentRatio={setContentRatio} setTooltipContentRank={setContentRank} setTooltipContentFlag={setContentFlag} />
                                                    {(contentPeople > 0.1 ?
                                                        <ReactTooltip effect="float" className="tooltip-block">
                                                            <div className='tooltip-block-header'>
                                                                <img
                                                                    className="tooltip-block-img"
                                                                    src={contentFlag}
                                                                    alt="contentFlag"
                                                                />
                                                                {content}
                                                            </div>
                                                            {(contentPeople > 0.1 ?
                                                                <div className='tooltip-block-content'>
                                                                    <span>
                                                                        <h5>{numberWithCommas(contentPeople.toString())}</h5>
                                                                        Incidence
                                                                    </span>

                                                                    <span className='center-border'>
                                                                        <h5>1 in {numberWithCommas(contentRatio.toString())}</h5>
                                                                        Ratio
                                                                    </span>

                                                                    <span>
                                                                        <h5>{numberWithCommas(contentRank.toString())}</h5>
                                                                        People
                                                                    </span>
                                                                </div>
                                                                :
                                                                <div className='tooltip-block-content'>
                                                                    <span>
                                                                        <h5>&lt;0.1%</h5>
                                                                        Incidence
                                                                    </span>

                                                                    <span className='center-border'>
                                                                        <h5>1 in {numberWithCommas(contentRatio.toString())}</h5>
                                                                        Ratio
                                                                    </span>

                                                                    <span>
                                                                        <h5>{numberWithCommas(contentRank.toString())}</h5>
                                                                        People
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </ReactTooltip>
                                                        :
                                                        <ReactTooltip effect="float" className="tooltip-block">
                                                            {(content !== ""
                                                                ?
                                                                <div className='tooltip-block-header'>
                                                                    {content}
                                                                </div>
                                                                :
                                                                <div className='display-none'>
                                                                </div>
                                                            )}
                                                        </ReactTooltip>
                                                    )}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <div className='table-header d-flex'>
                                                        <div className='col-3'>Country</div>
                                                        <div className='col-3'>Names</div>
                                                        <div className='col-3'>Ratio</div>
                                                        <div className='col-3'>commonality</div>
                                                    </div>

                                                    <div className='scroll scroll-small table-body-scroll'>
                                                        {!firstnameDistribution.isLoading && !firstnameDistribution.isError
                                                            ?
                                                            <>
                                                                {
                                                                    firstnameDistribution.data.map((content, index) =>
                                                                        <div className='table-body row'>
                                                                            <div className='col-3 table-body-country'><img src={content.country.flag_link} alt="" />{content.country.country}</div>
                                                                            <div className='col-3 table-body-text'>{numberWithCommas(content.population.toString())}</div>
                                                                            <div className='col-3 table-body-text'>1 in {numberWithCommas(parseInt(content.country.population / content.population).toString())}</div>
                                                                            <div className='col-3 table-body-text'>{numberWithCommas(content.country.population.toString())}</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </>
                                                            : null
                                                        }
                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>

                                    <div className='origin-block mt-4' id="DistributionScroll">
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="Map">
                                            <div className='d-flex justify-content-between align-items-start w-100'>
                                                <h3 className='dashboard-title m-0'>Distribution Of Worldwide Records List</h3>
                                                {/* <Nav variant="pills" className="">
                                                        <Nav.Link eventKey="Map" className='distribution-nav'>
                                                            <img src={MapIcon} alt="MapIcon" className='me-2' />
                                                            Map
                                                        </Nav.Link>
                                                        <Nav.Link eventKey="second" className='distribution-nav'>
                                                            <img src={ListIcon} alt="ListIcon" className='me-2' />
                                                            List
                                                        </Nav.Link>
                                                    </Nav> */}
                                            </div>

                                            <Tab.Content className='w-100'>
                                                <div className='table-header d-flex'>
                                                    <div className='col-3'>Country</div>
                                                    <div className='col-3'>Names</div>
                                                    <div className='col-3'>Ratio</div>
                                                    <div className='col-3'>commonality</div>
                                                </div>

                                                <div className='scroll scroll-small table-body-scroll'>
                                                    {!firstnameDistribution.isLoading && !firstnameDistribution.isError
                                                        ?
                                                        <>
                                                            {
                                                                firstnameDistribution.data.map((content, index) =>
                                                                    <div className='table-body row'>
                                                                        <div className='col-3 table-body-country'><img src={content.country.flag_link} alt="" />{content.country.country}</div>
                                                                        <div className='col-3 table-body-text'>{numberWithCommas(content.population.toString())}</div>
                                                                        <div className='col-3 table-body-text'>1 in {numberWithCommas(parseInt(content.country.population / content.population).toString())}</div>
                                                                        <div className='col-3 table-body-text'>{numberWithCommas(content.country.population.toString())}</div>
                                                                    </div>
                                                                )
                                                            }
                                                        </>
                                                        : null
                                                    }
                                                </div>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </>
                                :
                                null
                            }
                            {!firstnameHeight.isLoading && !firstnameHeight.isError && !firstnameSalary.isLoading && !firstnameSalary.isError
                                ?
                                <div className='origin-block mt-4' id="DemographicsScroll">
                                    <h3 className='dashboard-title m-0 d-flex justify-content-between w-100'>Demographics <span><img src={us}></img>USA</span></h3>
                                    <div className='d-flex justify-content-between align-items-start w-100'>
                                        <div className='base-info-card demographic-info-card'>
                                            <img src={AverageHeight} alt="AverageHeight" className='mb-2' />
                                            <p className='base-info-title'>
                                                Average Height
                                                {/* <img src={InfoButton} alt="" className='ms-2' /> */}
                                            </p>
                                            <h3 className='base-info-value'>{firstnameHeight.data.avg_height}</h3>
                                        </div>

                                        <div className='base-info-card demographic-info-card'>
                                            <img src={AverageSalary} alt="AverageSalary" className='mb-2' />
                                            <p className='base-info-title'>
                                                Average Salary
                                                {/* <img src={InfoButton} alt="" className='ms-2' /> */}
                                            </p>
                                            <h3 className='base-info-value'>{numberWithCommas(firstnameSalary.data.salary.toString())}</h3>
                                        </div>
                                    </div>

                                </div>
                                :
                                null
                            }

                            {/* {!firstnamePolitics.isLoading && !firstnamePolitics.isError
                                        ?
                                        <div className='d-flex justify-content-between align-items-start w-100 mt-5'>
                                            <div className='col-6 align-items-center justify-content-center d-flex flex-column'>
                                                <p className='base-info-title pb-3 border-none'>
                                                    Religion
                                                </p>
                                                <ChartAverage data={dataReligion} />
                                            </div>
                                            <div className='col-6 align-items-center justify-content-center d-flex flex-column'>
                                                <p className='base-info-title pb-3 border-none'>
                                                    Political Affiliation
                                                </p>
                                                <ChartAverage data={dataPolitics} />
                                            </div>
                                        </div>
                                        :
                                        null
                                    } */}

                            <div className='origin-block mt-4 pb-5' id="TimelineScroll">
                                <h3 className='dashboard-title m-0 d-flex justify-content-between w-100'>
                                    <div>Baby Name Records
                                        <small>
                                            &nbsp;(based on data we found on <a href="https://www.ssa.gov/">ssa.gov</a>)
                                        </small>
                                    </div>
                                    <div>
                                        <span>
                                            <img src={us}></img>
                                            USA
                                        </span>
                                    </div>
                                </h3>
                                <div className="bar-chart-position">Number of records</div>
                                <div className='timeline-block'>
                                    <Timeline />
                                </div>
                                <div className="chart-text">Years</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DashboardFooter />
        </div>
    )
}

export default ForenameJsx;