import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useGetPrefixQuery } from "../../redux/services/filters";

const Prefix = () => {
    const [prefixTest, setPrefixTest] = useState("");
    const AllPrefix = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    const [firstnamePrefix, setFirstnamePrefix] = useState("")
    const letterPrefix = useGetPrefixQuery(firstnamePrefix)

    const handleClickForenamePrefix = (content) => {
        setPrefixTest(content)
        setFirstnamePrefix(content)
    };

    return (
        <div className='prefix scroll scroll-small'>
            {
                AllPrefix.map((content, index) =>
                    <Fragment key={index}>
                        {prefixTest === content
                            ?
                            <div key={content} className="h-100 d-flex justify-content-center align-items-center prefix-full">
                                <button
                                    onClick={() => {
                                        setPrefixTest("")
                                    }}
                                    className="active"
                                >
                                    {content}
                                </button>
                                {!letterPrefix.isLoading && !letterPrefix.isError
                                    ?
                                    <>
                                        {
                                            letterPrefix.data.map((contentPrefix, index) =>
                                                <Link
                                                    to={`/find_by_prefix/${contentPrefix}`}
                                                    key={index}
                                                    className="prefix-link"
                                                >
                                                    {contentPrefix}
                                                </Link>
                                            )
                                        }
                                    </>
                                    :
                                    null
                                }
                            </div>
                            :
                            <button
                                onClick={() => {
                                    setPrefixTest(content)
                                    handleClickForenamePrefix(content)
                                }}
                            >
                                {content}
                            </button>
                        }
                    </Fragment>
                )
            }
        </div>
    )
}

export default Prefix;