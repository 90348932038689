import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const utilsApi = createApi({
    reducerPath: 'utilsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://family-tree-fastapi-prod-utpn6iuzbq-uc.a.run.app/api/',

    }),
    endpoints: (builder) => ({
        getAllFirstname: builder.query({
            query: (prefix) => ({
                url: `v2/identities/forenames?limit=50&skip=0&prefix=${prefix}`,
                method: "GET"
            }),
        }),
        postEmail: builder.mutation({
            query: (email) => ({
                url: `v2/users/subscribe?email=${email}`,
                method: "POST"
            }),
        }),
        getAllFirstnameLastname: builder.query({
            query: (prefix) => ({
                url: `v2/identities/forenames_surnames?sort=${prefix.sort}&limit=50&skip=${prefix.page * 50 - 50}&prefix=${prefix.forename}`,
                method: "GET"
            }),
        }),
    })

})

export const {
    useGetAllFirstnameQuery,
    usePostEmailMutation,
    useGetAllFirstnameLastnameQuery
} = utilsApi