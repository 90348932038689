import HeaderJsx from "../components/HeaderJsx";
import DashboardFooter from "../components/DashboardFooter";
import ImagesBG from '../../assets/img/ImagesBG.svg';
import { Link } from "react-router-dom";
import { useState } from "react";
import ShowPassword from '../../assets/img/ShowPassword.svg';
import HidePassword from '../../assets/img/HidePassword.svg';

const Login = () => {
    const [passwordStatus, setPasswordStatus] = useState(false);

    return (
        <div>
            <HeaderJsx />
                <div className="d-flex justify-content-center align-items-center height-full-size login-block">
                    <img
                        src={ImagesBG}
                        alt="ImagesBG"
                    />
                    <div className="login-form">
                        <h2 className="reg-title text-center">Login!</h2>

                        <div className="reg-field w-100 mb-4">
                            <p>Email</p>
                            <input type="text" placeholder="Enter email" className="w-100" />
                            <span></span>
                        </div>

                        <div className="reg-field w-100 mb-4">
                            <p>Password</p>
                            <div className="position-relative">
                                <input type={passwordStatus ? "text" : "password"} placeholder="Enter password" className="w-100" />
                                <span></span>
                                <img
                                    src={passwordStatus ? ShowPassword : HidePassword}
                                    alt={passwordStatus ? "ShowPassword" : "HidePassword"}
                                    className={passwordStatus ? "password-status" : "password-status password-status-hide"}
                                    onClick={() => {setPasswordStatus(!passwordStatus)}}
                                />
                            </div>
                        </div>
                        <button className="reg-button disable-reg mb-4">Login</button>
                        <p className="reg-text text-center">Don’t have an account? <Link to={`/registration`}>Register</Link></p>
                    </div>
                </div>
            <DashboardFooter />
        </div>
    )
}

export default Login;