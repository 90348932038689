import { Link } from 'react-router-dom';
import { useGetFirstnameByPrefixQuery } from '../../redux/services/fitstname';

const NamesBlock = ({ latter }) => {
    const firstnameByPrefix = useGetFirstnameByPrefixQuery(latter, { skip: !latter });

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            {!firstnameByPrefix.isLoading && !firstnameByPrefix.isError && firstnameByPrefix.data.length !== 0
                ?
                <div className='names-block mb-4' id={latter + "Scroll"}>
                    <div className='names-block-header w-100 d-flex justify-content-between align-items-start'>
                        <h3>{latter}</h3>
                        <Link to={`/find_by_prefix/${latter}`} onClick={scrollToTop}>View All</Link>
                    </div>
                    <div className='names-block-body'>
                        {
                            firstnameByPrefix.data.map(data =>
                                <Link to={`/lastname/${data.forename}`} onClick={scrollToTop}>{data.forename}</Link>
                            )
                        }
                    </div>
                </div>
                :
                null
            }
        </>
    )
}

export default NamesBlock;