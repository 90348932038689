import HeaderJsx from "../components/HeaderJsx";
import DashboardFooter from "../components/DashboardFooter";
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Filter from "../../assets/img/Filter.svg";
import Search from '../../assets/img/Search.svg';
import Clean from '../../assets/img/Clean.svg';
import { useGetFirstnameByPrefixQuery } from "../../redux/services/fitstname";
import Tabs from "react-bootstrap/esm/Tabs";
import Tab from 'react-bootstrap/Tab';
import { useGetAllFirstnameLastnameQuery } from "../../redux/services/utilsv2";
import Pagination from "../components/Pagination";
import TotalNames from '../../assets/img/TotalNames.svg';

const SearchByPrefix = () => {
    let params = useParams();
    const [firstnameByPrefix, setFirstnameByPrefix] = useState("")
    const result = useGetFirstnameByPrefixQuery(firstnameByPrefix, { skip: !firstnameByPrefix })
    const [searchDisplay, setSearchDisplay] = useState(false);
    const [search, setSearch] = useState(params.forename);
    const [autoComplete, setAutoComplete] = useState(false);
    const [filter, setFilter] = useState(false);
    const [page, setPage] = useState(1);

    const [dataReq, setDataReq] = useState({ forename: params.forename, sort: "A - Z" })
    const allFirstname = useGetAllFirstnameLastnameQuery({ ...dataReq, page })

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    function numberWithCommas(x) {
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    useEffect(() => {
        setSearch(params.forename)
        setDataReq({ forename: params.forename, sort: "A - Z" })
        if (result.data)
            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].forename.toLowerCase() === search.toLowerCase()) {
                    return setSearchDisplay(true);
                } else {
                    setSearchDisplay(false)
                }
            }
    }, [params]);

    const onSearchChanged = e => {
        if (search !== "") {
            setAutoComplete(true)
        }
        setSearch(e.target.value)
        if (e.target.value !== "")
            setFirstnameByPrefix(e.target.value)
    }

    const AllSearchLastnameByPrefix = () => {
        if (allFirstname.data.total_count > 0) {
            return (
                <>
                    {
                        allFirstname.data.surnames.map((firstnameData, index) =>
                            <div className="search-by-prefix-block-item" key={index}>
                                <Link to={`/lastname/${firstnameData.value[0] + firstnameData.value.substr(1).toLowerCase()}`} onClick={scrollToTop} className="prefix-firstname-link">
                                    <p className="prefix-firstname-title">{firstnameData.value}</p>
                                </Link>
                                <div className='popular-first-total-names'>
                                    <img src={TotalNames} alt="" />
                                    {numberWithCommas(firstnameData.amount_around_the_world.toString())}
                                </div>
                            </div>
                        )
                    }
                    {allFirstname.data.total_count > 50 && (
                        <div style={{ marginTop: '40px' }}>
                            <Pagination
                                totalCount={allFirstname.data.total_count}
                                currentPage={page}
                                itemsPerPage={50}
                                onPageChange={setPage}
                            />
                        </div>
                    )}
                </>
            )
        } else {
            return (
                <>
                    Sorry, we currently do not have records for "{params.forename}"
                </>
            )
        }
    }

    return (
        <div>
            <HeaderJsx />
            <div className="height-full-size">
                <div className="search-header">
                    <div style={{ display: 'flex', gap: '16px' }}>
                        <Link to={`/`} onClick={scrollToTop}>Home</Link>
                        <span className="second-navigation-slash">/</span>
                        <span className="active">Search Results</span>
                    </div>
                    <button className={filter ? "filter-button filter-active" : "filter-button"} onClick={() => { setFilter(!filter) }}>
                        <img
                            src={Filter}
                            alt="Filter"
                            className="me-2"
                        />
                        Filter
                        <div className="filter-block">
                            <span onClick={() => { setDataReq({ forename: params.forename, sort: "A - Z" }) }}>
                                A - Z
                            </span>
                            <span onClick={() => { setDataReq({ forename: params.forename, sort: "Z - A" }) }}>
                                Z - A
                            </span>
                            <span onClick={() => { setDataReq({ forename: params.forename, sort: "Lowest to Highest" }) }}>
                                Lowest to Highest
                            </span>
                            <span onClick={() => { setDataReq({ forename: params.forename, sort: "Highest to Lowest" }) }}>
                                Highest to Lowest
                            </span>
                        </div>
                    </button>
                </div>
                <div className="d-flex justify-content-center align-items-center p-6 search-by-prefix-mobile-wrapper">
                    <div className="conteiner d-flex justify-content-between align-items-start">

                        <div className="search-content">

                            <div className="header-search-block m-0 w-100">
                                <input
                                    placeholder="Start typing to search..."
                                    className="header-input search-page-field-color"
                                    type="text"
                                    id="postTitle"
                                    name="postTitle"
                                    value={search}
                                    onChange={onSearchChanged}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            if (e.target.value !== "") {
                                                document.getElementById("link-addon3").click()
                                            }
                                        }
                                    }}
                                />
                                <img
                                    src={Search}
                                    alt="Search"
                                    className="header-search-icon header-search-page-icon"
                                />
                                {search !== ""
                                    ?
                                    <img
                                        src={Clean}
                                        alt="Clean"
                                        className="header-search-clean-icon header-search-page-clean-icon"
                                        onClick={() => { setSearch("") }}
                                    />
                                    : null
                                }
                                {searchDisplay && search !== ""
                                    ? (
                                        <Link
                                            to={`/lastname/${search}`}
                                            key={search}
                                            variant="btn btn-light" id="link-addon3"
                                            className="header-search-icon header-search-page-icon"
                                            onClick={() => { setAutoComplete(false) }}
                                        >
                                            <img
                                                src={Search}
                                                alt="Search"
                                            />
                                        </Link>
                                    )
                                    : !searchDisplay && search !== "" ? (
                                        <Link
                                            to={`/find_by_prefix/${search}`}
                                            key={search}
                                            variant="btn btn-light" id="link-addon3"
                                            className="header-search-icon header-search-page-icon"
                                            onClick={() => { setAutoComplete(false) }}
                                        >
                                            <img
                                                src={Search}
                                                alt="Search1"
                                            />
                                        </Link>
                                    ) :
                                        <Link
                                            to={`/`}
                                            key={search}
                                            variant="btn btn-light" id="link-addon3"
                                            className="header-search-icon header-search-page-icon"
                                            onClick={() => { setAutoComplete(false) }}
                                        >
                                            <img
                                                src={Search}
                                                alt="Search2"
                                            />
                                        </Link>

                                }
                                {result.data && search !== "" && autoComplete ? (
                                    <div className="autocomplite scroll">
                                        {result.data
                                            .map((value, index) => {
                                                return (
                                                    <Link to={`/lastname/${value.forename}`}
                                                        className="autocomplite-option"
                                                        key={index}
                                                        tabIndex="0"
                                                        onClick={() => { setAutoComplete(false) }}
                                                    >
                                                        {value.forename}
                                                    </Link>
                                                );
                                            })}
                                    </ div>
                                ) : null
                                }
                            </div>

                            <Tabs
                                id="controlled-tab-example"
                                className="mb-3 conutry-content-header-nav"
                            >

                                {/* <Tab eventKey="All" title="All" tabClassName="dashboard-button">
                                    {!allFirstname.isLoading && !allFirstname.isError
                                        ?
                                        <>
                                            <AllSearchByPrefix />
                                        </>
                                        : null

                                    }
                                </Tab>
                                <Tab eventKey="First Names" title="First Names" tabClassName="dashboard-button">
                                    {!allFirstname.isLoading && !allFirstname.isError
                                        ?
                                        <>
                                            <AllSearchFirstnameByPrefix />
                                        </>
                                        : null

                                    }
                                </Tab> */}
                                <Tab eventKey="Last Names" title="Last Names" tabClassName="dashboard-button">
                                    {!allFirstname.isLoading && !allFirstname.isError
                                        ?
                                        <>
                                            <AllSearchLastnameByPrefix />
                                        </>
                                        : null

                                    }
                                </Tab>

                            </Tabs>
                        </div>

                        {/* <div className="search-integration">
                                <h3 className="search-integration-title">Curious about someone’s public records?</h3>
                                <p className="search-integration-text">Learn more by searching their name below.</p>
                                <div className="classic-input mb-2">
                                    <input type="text" placeholder="First name" />
                                    <span></span>
                                </div>
                                <div className="classic-input mb-3">
                                    <input type="text" placeholder="Last name" />
                                    <span></span>
                                </div>
                                <button className="reg-button">Search Records</button>
                            </div> */}

                    </div>
                </div>
            </div>
            <DashboardFooter />
        </div>
    )
}

export default SearchByPrefix;