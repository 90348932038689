import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeaderJsx from "../components/HeaderJsx";
import Search from '../../assets/img/Search.svg';
import Clean from '../../assets/img/Clean.svg';
import Image1 from '../../assets/img/Image1.svg';
import Image2 from '../../assets/img/Image2.svg';
import Image3 from '../../assets/img/Image3.svg';
import Image4 from '../../assets/img/Image4.svg';
import Image5 from '../../assets/img/Image5.svg';
import Image6 from '../../assets/img/Image6.svg';
import Image7 from '../../assets/img/Image7.svg';
import Image8 from '../../assets/img/Image8.svg';
import Image9 from '../../assets/img/Image9.svg';
import NameCatalog from '../../assets/img/NameCatalog.svg';
import Explore from '../../assets/img/Explore.svg';
import Star from '../../assets/img/YellowStar.svg';
import PopularData from "../components/PopularData";
import DashboardFooter from "../components/DashboardFooter";
import { useGetAllFirstnameLastnameQuery } from "../../redux/services/utilsv2";
import CloseIcon from '../../assets/img/CloseWhite.svg';

const MainJsx = () => {
    const [firstnameByPrefix, setFirstnameByPrefix] = useState("")
    const result = useGetAllFirstnameLastnameQuery({ forename: firstnameByPrefix, page: 1, sort: "A - Z" }, { skip: !firstnameByPrefix })
    const [searchDisplay, setSearchDisplay] = useState(false);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [successfullySent, setSuccessfullySent] = useState(false);

    console.log(open, successfullySent)

    useEffect(() => {
        if (result.data && result.data.surnames.length > 0) {
            for (let i = 0; i < result.data.surnames.length; i++) {
                if (result.data.surnames[i].value.toLowerCase() === search.toLowerCase()) {
                    return setSearchDisplay(true);
                } else {
                    setSearchDisplay(false)
                }
            }
        }
    }, [search, result.data]);

    const onSearchChanged = e => {
        setSearch(e.target.value)
        if (e.target.value !== "")
            setFirstnameByPrefix(e.target.value)
    }

    return (
        <div>
            <HeaderJsx />
            {open ? (
                <div className='clibboard' onClose={() => setOpen(false)} onClick={() => { setOpen(false) }}>
                    {successfullySent ? 'Thanks for subscribing.' : 'You are already subscribed.'}
                    <img src={CloseIcon} alt="" />
                </div>
            ) : null}
            <div className="start-block">
                <div className="conteiner conteiner-modal d-flex flex-column justify-content-between align-items-center">
                    <div>
                        <h1 className="main-title">
                            Start learning about names!
                        </h1>
                        <div className="main-search-block">
                            <input
                                autoComplete="off"
                                placeholder="Start typing to search..."
                                className="main-input"
                                type="text"
                                id="postTitle"
                                name="postTitle"
                                value={search}
                                onChange={onSearchChanged}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (e.target.value !== "") {
                                            document.getElementById("link-addon1").click()
                                        }
                                    }
                                }}
                            />
                            {searchDisplay && search !== ""
                                ? (
                                    <Link className="search-btn" to={`/lastname/${search}`} >
                                        Search
                                    </Link>
                                )
                                : !searchDisplay && search !== "" ? (
                                    <Link className="search-btn" to={`/find_by_prefix/${search}`} >
                                        Search
                                    </Link>
                                ) :
                                    <Link className="search-btn">
                                        Search
                                    </Link>

                            }
                            {search !== ""
                                ?
                                <img
                                    src={Clean}
                                    alt="Clean"
                                    className="main-search-clean-icon"
                                    onClick={() => { setSearch("") }}
                                />
                                : null
                            }
                            {searchDisplay && search !== ""
                                ? (
                                    <Link
                                        to={`/lastname/${search}`}
                                        key={search}
                                        variant="btn btn-light" id="link-addon1"
                                        className="main-search-icon"
                                    >
                                        <img
                                            src={Search}
                                            alt="Search"
                                        />
                                    </Link>
                                )
                                : !searchDisplay && search !== "" ? (
                                    <Link
                                        to={`/find_by_prefix/${search}`}
                                        key={search}
                                        variant="btn btn-light" id="link-addon1"
                                        className="main-search-icon"
                                    >
                                        <img
                                            src={Search}
                                            alt="Search1"
                                        />
                                    </Link>
                                ) :
                                    <Link
                                        key={search}
                                        variant="btn btn-light" id="link-addon1"
                                        className="main-search-icon"
                                    >
                                        <img
                                            src={Search}
                                            alt="Search2"
                                        />
                                    </Link>

                            }
                            {result.data && search !== "" ? (
                                <div className="autocomplite scroll">
                                    {result.data.surnames.map((value, index) => {
                                        return (
                                            <Link to={`/lastname/${value.value}`}
                                                className="autocomplite-option"
                                                key={index}
                                                tabIndex="0"
                                            >
                                                {value.value}
                                            </Link>
                                        );
                                    })}
                                </ div>
                            ) : null
                            }
                        </div>
                    </div>

                    <div className="main-grid-images">
                        <img
                            src={Image1}
                            alt="Image1"
                            className="image1"
                        />
                        <img
                            src={Image2}
                            alt="Image3"
                            className="image2"
                        />
                        <img
                            src={Image3}
                            alt="Image3"
                            className="image3"
                        />
                        <img
                            src={Image4}
                            alt="Image4"
                            className="image4"
                        />
                        <img
                            src={Image5}
                            alt="Image5"
                            className="image5"
                        />
                        <img
                            src={Image6}
                            alt="Image6"
                            className="image6"
                        />
                        <img
                            src={Image7}
                            alt="Image7"
                            className="image7"
                        />
                        <img
                            src={Image8}
                            alt="Image8"
                            className="image8"
                        />
                        <img
                            src={Image9}
                            alt="Image9"
                            className="image9"
                        />
                    </div>
                </div>
            </div>
            <div className="landing-info-card w-full">
                <div className="conteiner d-flex justify-content-between align-items-start landing-info-card-modal">

                    <div className="lending-card">
                        <div className="lending-card-img">
                            <img
                                src={NameCatalog}
                                alt="NameSearch"
                            />
                        </div>
                        <h3>Name Catalog</h3>
                        <p>View our large catalog of names and discover amazing information.</p>
                        <Link to={`/explore`} className="lending-card-button">View Catalog</Link>
                    </div>
                    <div className="lending-card">
                        <div className="lending-card-img">
                            <img
                                src={Explore}
                                alt="Explore"
                            />
                        </div>
                        <h3>Explore</h3>
                        <p>Explore popular names and learn about their origins.</p>
                        <Link to={`/explore`} className="lending-card-button" style={{ "background": "#317199" }}>Explore Now</Link>
                    </div>
                    <div className="lending-card">
                        <div className="lending-card-img">
                            <img
                                src={Star}
                                alt="Star"
                            />
                        </div>
                        <h3>Name of the Day</h3>
                        <p>Learn about a new name of the day each day - maybe it’ll be yours!</p>
                        <Link to={`/explore`} className="lending-card-button" style={{ "background": "#F2C94C" }}>Name of the Day</Link>
                    </div>
                </div>
            </div>

            <PopularData setOpen={setOpen} setSuccessfullySent={setSuccessfullySent} />
            <DashboardFooter />
        </div>
    )
}

export default MainJsx;