import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const firstnameLastnameApi = createApi({
    reducerPath: 'firstnameLastnameApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://family-tree-fastapi-prod-utpn6iuzbq-uc.a.run.app/api/' }),
    endpoints: (builder) => ({
        getFirstnameLastnameByPrefix: builder.query({
            query: (prefix) => `identities/forenames-surnames/find_by_prefix?prefix=${prefix}&limit=20`
        })
    })

})

export const { useGetFirstnameLastnameByPrefixQuery } = firstnameLastnameApi