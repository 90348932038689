import React, { useEffect } from 'react';
import DashboardFooter from '../components/DashboardFooter';
import { Link, useNavigate, useParams } from "react-router-dom";
import LinkImg from '../../assets/img/Link.svg';
import Explore from '../../assets/img/Explore.svg';
import { useMemo, useState } from "react";
import ForenameNav from '../components/ForenameNav';
import { useGetExtraLastnameGeneratedDataQuery, useGetLastnameGeneratedDataQuery, useGetListSimilarNamesQuery } from '../../redux/services/lastname';
import DiscoverHistoryMobile from '../components/DiscoverHistoryMobile';
import PeoplesIcon from '../../assets/img/peoples-icon.svg'
import TopIcon from '../../assets/img/top.svg'
import MobilePublicRecords from '../components/MobilePublicRecords';
import NamesHeader from '../components/NamesHeader';
import PrefixSeoPage from '../components/PrefixSeoPage';
import { INTERNAL_LINKS } from '../../utils/constants';
import { Helmet } from "react-helmet";
import CloseIcon from '../../assets/img/CloseWhite.svg';

const LastnameJsx = () => {
    const [open, setOpen] = useState(false);

    const params = useParams();
    const navigate = useNavigate();

    const { data: lastnameGeneratedData, isError: isErrorInGeneratedData } = useGetLastnameGeneratedDataQuery(params.lastname)
    const { data: extraLastnameGeneratedData, isError: isErrorInExtraGeneratedData } = useGetExtraLastnameGeneratedDataQuery(params.lastname)
    const { data: similarNamesData } = useGetListSimilarNamesQuery(params.lastname)

    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(window.location.toString());
        setTimeout(() => {
            setOpen(false)
        }, 2000)
    };

    const countToEnding = (count) => {
        if (count) {
            const lastNumber = count.toString().slice(-1)
            let ending = ''

            switch (lastNumber) {
                case '1':
                    ending = 'st'
                    break;
                case '2':
                    ending = 'nd'
                    break;
                case '3':
                    ending = 'rd'
                    break;
                default:
                    ending = 'th'
                    break;
            }

            return ending
        }

        return 'th'
    }

    const convertNameForHistoricalFigures = (name) => {
        const splittedName = name.split(' ')

        let convertedToHtml = (
            <h3 className='historical-figure-info-name'>
                {splittedName[0]}
                <span className='historical-figure-info-name-blue'>{splittedName[1]}</span>
            </h3>
        )

        return convertedToHtml
    }

    const shortDescriptionWithLinks = useMemo(() => {

        if (extraLastnameGeneratedData?.short_description) {
            let tempData = extraLastnameGeneratedData.short_description;

            Object.keys(INTERNAL_LINKS).forEach((key) => {
                const link = `<a style="text-decoration: none" href="${INTERNAL_LINKS[key]}">${key}</a>`;
                tempData = tempData.split(key).join(link);
            });

            return (
                <span
                    className='name-information-summary-text'
                    dangerouslySetInnerHTML={{ __html: tempData }}
                />
            );
        } else {
            return <span className='name-information-summary-text'></span>;
        }

    }, [extraLastnameGeneratedData]);

    const originsWithLinks = useMemo(() => {
        if (lastnameGeneratedData?.origins) {
            let tempData = lastnameGeneratedData?.origins;

            Object.keys(INTERNAL_LINKS).forEach((key) => {
                const link = `<a style="text-decoration: none" href="${INTERNAL_LINKS[key]}">${key}</a>`;
                tempData = tempData.split(key).join(link);
            });

            return (
                <span
                    className='genealogy-text'
                    dangerouslySetInnerHTML={{ __html: tempData }}
                />
            );
        } else {
            <span className='genealogy-text'></span>
        }
    }, [lastnameGeneratedData]);

    const georgraphicDistributionWithLinks = useMemo(() => {
        if (lastnameGeneratedData?.geo) {
            let tempData = lastnameGeneratedData?.geo;

            Object.keys(INTERNAL_LINKS).forEach((key) => {
                const link = `<a style="text-decoration: none" href="${INTERNAL_LINKS[key]}">${key}</a>`;
                tempData = tempData.split(key).join(link);
            });

            return (
                <span
                    className='genealogy-text'
                    dangerouslySetInnerHTML={{ __html: tempData }}
                />
            );
        } else {
            <span className='genealogy-text'></span>
        }
    }, [lastnameGeneratedData])

    const migrationAndImigrationWithLinks = useMemo(() => {
        if (lastnameGeneratedData?.migration_and_immigration) {
            let tempData = lastnameGeneratedData.migration_and_immigration;

            Object.keys(INTERNAL_LINKS).forEach((key) => {
                const link = `<a style="text-decoration: none" href="${INTERNAL_LINKS[key]}">${key}</a>`;
                tempData = tempData.split(key).join(link);
            });

            return (
                <span
                    className='genealogy-text'
                    dangerouslySetInnerHTML={{ __html: tempData }}
                />
            );
        } else {
            <span className='genealogy-text'></span>
        }
    }, [lastnameGeneratedData])

    const variationsWithLinks = useMemo(() => {
        if (lastnameGeneratedData?.variation) {
            let tempData = lastnameGeneratedData?.variation;

            Object.keys(INTERNAL_LINKS).forEach((key) => {
                const link = `<a style="text-decoration: none" href="${INTERNAL_LINKS[key]}">${key}</a>`;
                tempData = tempData.split(key).join(link);
            });

            return (
                <span
                    className='genealogy-text'
                    dangerouslySetInnerHTML={{ __html: tempData }}
                />
            );
        } else {
            <span className='genealogy-text'></span>
        }
    }, [lastnameGeneratedData])

    useEffect(() => {
        if (params.lastname && params.lastname.toLowerCase() !== params.lastname) {
            navigate(`/lastname/${params.lastname.toLowerCase()}`)
        }
    }, [params, navigate])

    return (
        <div>
            <Helmet>
                <title>{params.lastname[0].toUpperCase() + params.lastname.slice(1)} last name - genealogy, origins, and statistics</title>
                <meta
                    name="description"
                    content={`There are ${extraLastnameGeneratedData?.amount_around_the_world} people with the last name ${params.lastname[0].toUpperCase() + params.lastname.slice(1)} in the USA. Find more statistics and learn about the history of this name here.`}
                />
            </Helmet>
            {open ? (
                <div className='clibboard' onClose={() => setOpen(false)} onClick={() => { setOpen(false) }}>
                    Copied to clipboard
                    <img src={CloseIcon} alt="" />
                </div>
            ) : null}
            <NamesHeader
                handleCopy={handleClick}
                mainData={lastnameGeneratedData}
                extraData={extraLastnameGeneratedData}
            />
            <div className="height-full-size names-wrapper-container">
                <div className="search-header second-navigation-wrapper">
                    <div className='conteiner second-navigation-names'>
                        <Link to={`/`}>Home</Link>
                        <span className='second-navigation-slash'>/</span>
                        <span className='cursor-pointer' onClick={() => navigate(`/find_by_prefix/${params.lastname}`)}>Search Results</span>
                        <span className='second-navigation-slash'>/</span>
                        <span className="active">{params.lastname[0].toUpperCase() + params.lastname.slice(1)}</span>
                    </div>
                </div>
                <div className="search-header second-navigation-wrapper py-4">
                    <div className='conteiner d-flex justify-content-between align-items-center'>
                        <h1 className='page-title'>{params.lastname} <span className='span-status'>Last Name</span></h1>
                        <div className='d-flex justify-content-end align-items-center'>

                            <div className='icon-block' onClick={handleClick}>
                                <img src={LinkImg} alt="" />
                            </div>

                            <Link className='m-0 explore-text' to={`/explore`}>
                                <img src={Explore} alt="" className='me-2' />
                                <span className='explore-text-name'>
                                    Explore Names
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='p-6 names-content-block bg-grey'>
                    <div className='d-flex justify-content-between align-items-start conteiner'>
                        <ForenameNav
                            similar_list={similarNamesData ? similarNamesData : []}
                            lastname={true}
                            mainData={lastnameGeneratedData}
                            extraData={extraLastnameGeneratedData}
                        />
                        <div className='main-content col-9 full-width-content-mobile'>
                            {!isErrorInExtraGeneratedData && extraLastnameGeneratedData?.short_description && (
                                <>
                                    <div className='name-information-summary' id="SummaryScroll">
                                        <h2 className='name-information-summary-heading'>Summary</h2>
                                        {shortDescriptionWithLinks}
                                    </div>
                                </>
                            )}
                            {/* <DiscoverHistoryMobile /> */}
                            {!isErrorInExtraGeneratedData && (
                                <>
                                    <div className='name-information-stats' id="OverviewScroll">
                                        <div className='information-stats-block'>
                                            <img className='information-stats-icon' width={37} src={PeoplesIcon} alt="" />
                                            <span className='information-stats-label'>There are</span>
                                            <h5 className='information-stats-value'>{extraLastnameGeneratedData?.amount_around_the_world}</h5>
                                            <span className='information-stats-label'>
                                                {params.lastname[0].toUpperCase() + params.lastname.slice(1)}s in the USA
                                            </span>
                                        </div>
                                        <div className='information-stats-block'>
                                            <img className='information-stats-icon' width={27} src={TopIcon} alt="" />
                                            <span className='information-stats-label'>{params.lastname[0].toUpperCase() + params.lastname.slice(1)} is the</span>
                                            <h5 className='information-stats-value'>{extraLastnameGeneratedData?.rating_position_worldwide}{countToEnding(extraLastnameGeneratedData?.rating_position_worldwide)}</h5>
                                            <span className='information-stats-label'>
                                                most common last name in the USA
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                            {similarNamesData && (
                                <div className='name-information-related-names' id="RelatedScroll">
                                    <h2 className='related-names-heading'>Surnames related to {params.lastname[0].toUpperCase() + params.lastname.slice(1)}</h2>
                                    <div className='related-names-list'>
                                        {similarNamesData?.slice(0, 15).map((name, index) => (
                                            <Link style={{ textDecoration: 'none' }} to={`/lastname/${name.name.toLowerCase()}`} key={index}>
                                                <div className='related-names-item'>{name.name}</div>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {!isErrorInGeneratedData && lastnameGeneratedData?.historical_figures && lastnameGeneratedData?.historical_figures.length > 0 && (
                                <div className='name-information-historical-figures' id="HistoricalFiguresScroll">
                                    <h2 className='historical-figures-names-heading'>Historical figures and celebrities called {params.lastname[0].toUpperCase() + params.lastname.slice(1)}</h2>
                                    <div className='historical-firgures-list'>
                                        {lastnameGeneratedData?.historical_figures.map((historicalFigure, index) => (
                                            <div key={index} className='historical-figure-item'>
                                                <div className='historical-figure-item-information'>
                                                    {convertNameForHistoricalFigures(historicalFigure.name)}
                                                    <span className='historical-figure-item-profession'>{historicalFigure.profession}</span>
                                                    <span className='historical-figure-item-bio'>{historicalFigure.short_bio}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {/* <MobilePublicRecords /> */}
                            {!isErrorInGeneratedData && (lastnameGeneratedData?.origins || lastnameGeneratedData?.geo || lastnameGeneratedData?.variation || lastnameGeneratedData?.migration_and_immigration) && (
                                <>
                                    <div className='name-information-genealogy' id="GenealogyScroll">
                                        <h2 className='genealogy-heading'>{params.lastname[0].toUpperCase() + params.lastname.slice(1)} Genealogy</h2>
                                        {lastnameGeneratedData?.origins && (
                                            <div className='genealogy-block'>
                                                <span className='genealogy-heading-title'>Origins</span>
                                                {originsWithLinks}
                                            </div>
                                        )}
                                        {lastnameGeneratedData?.geo && (
                                            <div className='genealogy-block'>
                                                <span className='genealogy-heading-title'>Geographic Distribution</span>
                                                {georgraphicDistributionWithLinks}
                                            </div>
                                        )}
                                        {lastnameGeneratedData?.variation && (
                                            <div className='genealogy-block'>
                                                <span className='genealogy-heading-title'>Variations</span>
                                                {variationsWithLinks}
                                            </div>
                                        )}
                                        {lastnameGeneratedData?.migration_and_immigration && (
                                            <div className='genealogy-block'>
                                                <span className='genealogy-heading-title'>Migration and Immigration</span>
                                                {migrationAndImigrationWithLinks}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            <div className='name-letters-options' id="MoreLastNamesScroll">
                                <h2 className='letters-options-heading'>Last Names</h2>
                                <div className='letters-options-list'>
                                    <PrefixSeoPage isSurname={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DashboardFooter />
        </div>
    )
}

export default LastnameJsx;