import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const filtersApi = createApi({
    reducerPath: 'filtersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://family-tree-fastapi-prod-utpn6iuzbq-uc.a.run.app/api/',

    }),
    endpoints: (builder) => ({
        getFilters: builder.query({
            query: () => ({
                url: `v1/utils/filters`,
                method: "GET"
            }),
        }),
        getPrefix: builder.query({
            query: (statePrefix) => ({
                url: `v2/utils/prefixes?start_word=${statePrefix}`,
                method: "GET"
            }),
        }),
        getFirstnamePrefixAll: builder.query({
            query: () => ({
                url: `v1/identities/forenames_alphabet?limit=50&skip=0`,
                method: "GET"
            }),
        }),
    })

})

export const {
    useGetFiltersQuery,
    useGetPrefixQuery,
    useGetFirstnamePrefixAllQuery
} = filtersApi