import React from "react";
import { Link } from "react-scroll";
import { Link as LinkUrl } from "react-router-dom";

const ForenameNav = ({ similar_list, lastname, mainData, extraData }) => {
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className="forename-nav col-3">
            <div className="conutry-content-header-nav border-none m-0 flex-column">
                {extraData?.short_description && (
                    <Link to="SummaryScroll"
                        onClick={scrollToTop}
                        activeClass="active"
                        spy={true}
                        offset={-300}>
                        Summary
                    </Link>
                )}
                {extraData?.amount_around_the_world && extraData?.rating_position_worldwide && (
                    <Link to="OverviewScroll"
                        onClick={scrollToTop}
                        activeClass="active"
                        spy={true}
                        offset={-300}>
                        Overview
                    </Link>
                )}
                <Link to="RelatedScroll"
                    onClick={scrollToTop}
                    activeClass="active"
                    spy={true}
                    offset={-300}>
                    Related Surnames
                </Link>
                {mainData?.historical_figures && mainData?.historical_figures.length > 0 && (
                    <Link to="HistoricalFiguresScroll"
                        onClick={scrollToTop}
                        activeClass="active"
                        spy={true}
                        offset={-300}>
                        Historical Figures & Celebrities
                    </Link>
                )}
                {mainData?.geo && (
                    <Link to="GenealogyScroll"
                        activeClass="active"
                        spy={true}
                        offset={-300}
                        onClick={scrollToTop}>
                        Geneaology
                    </Link>
                )}
                <Link to="MoreLastNamesScroll"
                    activeClass="active"
                    spy={true}
                    offset={-300}
                    onClick={scrollToTop}>
                    More Last Names
                </Link>
            </div>
            <div className='left-navigation-divider'></div>
            {similar_list && similar_list.length > 0 && (
                <div className='left-navigation-similar-names-block'>
                    <h5 className='left-navigation-similar-names-title'>{lastname ? 'Similar Surnames' : 'Similar Names'}</h5>
                    <div className='similar-names-list'>
                        {similar_list.slice(0, 15).map((name, index) => (
                            <LinkUrl style={{ textDecoration: 'none' }} to={`/lastname/${name.name.toLowerCase()}`} key={index}>
                                <div className='similar-name-item'>{name.name}</div>
                            </LinkUrl>
                        ))}
                    </div>
                </div>
            )}
            {/* <div className='left-navigation-discover-family-history'>
                <h5 className='discover-family-heading'>Start discovering your family story.</h5>
                <p className='discover-family-description'>Search for a specific ancestor in FamilySearch. Even your best guess will do.</p>
                <div className='discovery-family-input-block'>
                    <label className='discovery-family-input-label'>First Names</label>
                    <input className='discovery-family-input' placeholder='First and Middle Names' type="text" />
                </div>
                <div className='discovery-family-input-block'>
                    <label className='discovery-family-input-label'>Last Names</label>
                    <input className='discovery-family-input' placeholder='Last or Maiden Names' type="text" />
                </div>
                <div className='discovery-family-input-block'>
                    <label className='discovery-family-input-label'>Place Lived</label>
                    <input className='discovery-family-input' placeholder='City, County, State, Country' type="text" />
                </div>
                <div className='discovery-family-input-block'>
                    <label className='discovery-family-input-label'>Birth Year</label>
                    <input className='discovery-family-input' placeholder='ex: 1978' type="text" />
                </div>
                <button className='discovery-family-button'>Search Records</button>
            </div> */}
        </div>
    )
}

export default ForenameNav;