import React from 'react'
import { useNavigate } from 'react-router-dom';

const allPrefixes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const PrefixSeoPage = ({ isSurname = false }) => {
    const navigate = useNavigate();

    const handleClickForenamePrefix = (content) => {
        if (isSurname) {
            navigate(`/find_by_prefix/${content}`)
        } else {
            navigate(`/find_by_prefix/${content}`)
        }
    };

    return (
        <div className='prefix scroll scroll-small'>
            {
                allPrefixes.map((content, index) =>
                    <button key={index} onClick={() => handleClickForenamePrefix(content)}>
                        {content}
                    </button>
                )
            }
        </div>
    )
}

export default PrefixSeoPage