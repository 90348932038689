import { useState } from "react";
import { Link } from "react-router-dom";
import HeaderJsx from "../components/HeaderJsx";
import Image1 from '../../assets/img/Image1.svg';
import Image2 from '../../assets/img/Image2.svg';
import Image3 from '../../assets/img/Image3.svg';
import Image4 from '../../assets/img/Image4.svg';
import Image5 from '../../assets/img/Image5.svg';
import Image6 from '../../assets/img/Image6.svg';
import Image7 from '../../assets/img/Image7.svg';
import Image8 from '../../assets/img/Image8.svg';
import ImagesBG from '../../assets/img/ImagesBG.svg';
import DashboardFooter from "../components/DashboardFooter";
import ShowPassword from '../../assets/img/ShowPassword.svg';
import HidePassword from '../../assets/img/HidePassword.svg';

const Registration = () => {
    const [passwordRelativeStatus, setPasswordRelativeStatus] = useState(false);
    const [passwordStatus, setPasswordStatus] = useState(false);

    return (
        <div>
            <HeaderJsx />
            <div className="d-flex height-full-size">
                <div className="reg-img-block">
                        <img
                            src={ImagesBG}
                            alt="ImagesBG"
                        />
                </div>
                <div className="reg-block">
                    <h2 className="reg-title">Let’s create an account!</h2>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-4">
                        <div className="reg-field">
                            <p>First Name</p>
                            <input type="text" placeholder="e.g. Connor" />
                            <span></span>
                        </div>
                        <div className="reg-field">
                            <p>First Name</p>
                            <input type="text" placeholder="e.g. Smith" />
                            <span></span>
                        </div>
                    </div>
                    
                    <div className="d-flex justify-content-between align-items-center w-100 mb-4">
                        <div className="reg-field">
                            <p>Email</p>
                            <input type="text" placeholder="e.g. connorsmith@gmail.com" />
                            <span></span>
                        </div>
                        <div className="reg-field">
                            <p>Phone*</p>
                            <input type="text" placeholder="(000) 000 - 0000" />
                            <span></span>
                        </div>
                    </div>
                    
                    <div className="d-flex justify-content-between align-items-center w-100 mb-4">
                        <div className="reg-field">
                            <p>Password</p>
                            <div className="position-relative">
                                <input type={passwordStatus ? "text" : "password"} placeholder="8 characters" />
                                <span></span>
                                <img
                                    src={passwordStatus ? ShowPassword : HidePassword}
                                    alt={passwordStatus ? "ShowPassword" : "HidePassword"}
                                    className={passwordStatus ? "password-status" : "password-status password-status-hide"}
                                    onClick={() => {setPasswordStatus(!passwordStatus)}}
                                />
                            </div>
                        </div>
                        <div className="reg-field">
                            <p>Confirm Password</p>
                            <div className="position-relative">
                                <input type={passwordRelativeStatus ? "text" : "password"} placeholder="8 characters" />
                                <span></span>
                                <img
                                    src={passwordRelativeStatus ? ShowPassword : HidePassword}
                                    alt={passwordRelativeStatus ? "ShowPassword" : "HidePassword"}
                                    className={passwordRelativeStatus ? "password-status" : "password-status password-status-hide"}
                                    onClick={() => {setPasswordRelativeStatus(!passwordRelativeStatus)}}
                                />
                            </div>
                        </div>
                    </div>
                    <button className="reg-button disable-reg mb-4">Register</button>
                    <p className="reg-text">Already have an account? <Link to={`/login`}>Login</Link></p>
                </div>
            </div>
            <DashboardFooter />
        </div>
    )
}

export default Registration;