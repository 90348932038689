import React, { Component, useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import TitleLogo from '../../assets/img/LogoBlack.svg';
import { Link } from "react-router-dom";
import Search from '../../assets/img/Search.svg';
import Clean from '../../assets/img/Clean.svg';
import { useGetFirstnameByPrefixQuery } from "../../redux/services/fitstname";
import MenuButton from '../../assets/img/MenuButton.svg';
import PublicRecords from '../../assets/img/PublicRecords.svg';
import DeathRecords from '../../assets/img/DeathRecords.svg';
import Obituaries from '../../assets/img/Obituaries.svg';
import BirthRecords from '../../assets/img/BirthRecords.svg';
import MarriageRecords from '../../assets/img/MarriageRecords.svg';
import DNATesting from '../../assets/img/DNATesting.svg';

const HeaderJsx = () => {
    const [firstnameByPrefix, setFirstnameByPrefix] = useState("")
    const [menu, setMenu] = useState(false)
    const result = useGetFirstnameByPrefixQuery(firstnameByPrefix, { skip: !firstnameByPrefix })
    const [searchDisplay, setSearchDisplay] = useState(false);
    const [search, setSearch] = useState('');
    const [autoComplete, setAutoComplete] = useState(false);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        if (result.data)
            for (let i = 0; i < result.data.length; i++) {
                if (result.data[i].forename.toLowerCase() === search.toLowerCase()) {
                    return setSearchDisplay(true);
                } else {
                    setSearchDisplay(false)
                }
            }
    }, [search, result.data]);

    const onSearchChanged = e => {
        if (search !== "") {
            setAutoComplete(true)
        }
        setSearch(e.target.value)
        if (e.target.value !== "")
            setFirstnameByPrefix(e.target.value)
    }

    return (
        <div className="header">
            <Navbar expand="xl" className="conteiner d-flex justify-content-between">
                <Container className="container-header-mobile" fluid>
                    <div className="header-menu-conent">
                        <Navbar.Brand className="d-flex align-items-center p-0 me-4">
                            <Link className="logo-text"
                                to={`/`}
                                onClick={scrollToTop}
                            >
                                <img src={TitleLogo} alt="TitleLogo" className="me-2" />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll" className="h-100">
                            <Nav className="justify-content-between d-flex w-100 h-100">
                                <div className="d-flex align-items-center menu-blocks">
                                    {/* <Link className="header-link" to={`/names`} onClick={scrollToTop}>Names</Link> */}
                                    <Link className="header-link" to={`/explore`} onClick={scrollToTop}>Explore</Link>
                                    <div className="menu-blocks-links">
                                        <a className="menu-item" href="https://ourpublicrecords.org/public-records/" target="_blank" rel="noreferrer">
                                            <img src={PublicRecords} alt="" />
                                            Public Records
                                        </a>
                                        <a className="menu-item" target="_blank" href="https://ourpublicrecords.org/obituaries/" rel="noreferrer">
                                            <img src={Obituaries} alt="" />
                                            Obituaries
                                        </a>
                                        <a className="menu-item" href="https://ourpublicrecords.org/death-records/" target="_blank" rel="noreferrer">
                                            <img src={DeathRecords} alt="" />
                                            Death Records
                                        </a>
                                        <a className="menu-item" href="https://ourpublicrecords.org/birth-records/" target="_blank" rel="noreferrer">
                                            <img src={BirthRecords} alt="" />
                                            Birth Records
                                        </a>
                                        <a className="menu-item" href="https://ourpublicrecords.org/marriage-records/" target="_blank" rel="noreferrer">
                                            <img src={MarriageRecords} alt="" />
                                            Marriage Records
                                        </a>
                                        <a className="menu-item" href="https://ourpublicrecords.org/dna-testing/" target="_blank" rel="noreferrer">
                                            <img src={DNATesting} alt="" />
                                            Dna Testing
                                        </a>
                                    </div>
                                </div>
                            </Nav>

                        </Navbar.Collapse>
                    </div>
                    <div className="header-search-block header-search-block-width h-100">
                        <input
                            autoComplete="off"
                            placeholder="Start typing to search..."
                            className="header-input"
                            type="text"
                            id="postTitle"
                            name="postTitle"
                            value={search}
                            onChange={onSearchChanged}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    if (e.target.value !== "") {
                                        document.getElementById("link-addon2").click()
                                    }
                                }
                            }}
                        />
                        {search !== ""
                            ?
                            <img
                                src={Clean}
                                alt="Clean"
                                className="header-search-clean-icon"
                                onClick={() => { setSearch("") }}
                                style={{ "right": "100px" }}
                            />
                            : null
                        }
                        {searchDisplay && search !== ""
                            ? (
                                <Link
                                    to={`/lastname/${search}`}
                                    key={search}
                                    variant="btn btn-light" id="link-addon2"
                                    className="header-search-icon"
                                    onClick={() => { setAutoComplete(false) }}
                                >
                                    <img
                                        src={Search}
                                        alt="Search"
                                    />
                                </Link>
                            )
                            : !searchDisplay && search !== "" ? (
                                <Link
                                    to={`/find_by_prefix/${search}`}
                                    key={search}
                                    variant="btn btn-light" id="link-addon2"
                                    className="header-search-icon"
                                    onClick={() => { setAutoComplete(false) }}
                                >
                                    <img
                                        src={Search}
                                        alt="Search1"
                                    />
                                </Link>
                            ) :
                                <Link
                                    to={`/`}
                                    key={search}
                                    variant="btn btn-light" id="link-addon2"
                                    className="header-search-icon"
                                    onClick={() => { setAutoComplete(false) }}
                                >
                                    <img
                                        src={Search}
                                        alt="Search2"
                                    />
                                </Link>

                        }
                        {result.data && search !== "" && autoComplete ? (
                            <div className="autocomplite scroll">
                                {result.data
                                    .map((value, index) => {
                                        return (
                                            <Link to={`/lastname/${value.forename}`}
                                                className="autocomplite-option"
                                                key={index}
                                                tabIndex="0"
                                                onClick={() => { setAutoComplete(false) }}
                                            >
                                                {value.forename}
                                            </Link>
                                        );
                                    })}
                            </ div>
                        ) : null
                        }

                        <div className={menu ? "menu-button menu-active" : "menu-button"} onClick={() => {
                            setMenu(!menu)
                        }}>
                            <img src={MenuButton} alt="MenuButton" className="menu-button-img" />
                            <div className="menu-block">
                                <a className="menu-item" href="https://ourpublicrecords.org/public-records/" target="_blank" rel="noreferrer">
                                    <img src={PublicRecords} alt="" />
                                    Public Records
                                </a>
                                <a className="menu-item" target="_blank" href="https://ourpublicrecords.org/obituaries/" rel="noreferrer">
                                    <img src={Obituaries} alt="" />
                                    Obituaries
                                </a>
                                <a className="menu-item" href="https://ourpublicrecords.org/death-records/" target="_blank" rel="noreferrer">
                                    <img src={DeathRecords} alt="" />
                                    Death Records
                                </a>
                                <a className="menu-item" href="https://ourpublicrecords.org/birth-records/" target="_blank" rel="noreferrer">
                                    <img src={BirthRecords} alt="" />
                                    Birth Records
                                </a>
                                <a className="menu-item" href="https://ourpublicrecords.org/marriage-records/" target="_blank" rel="noreferrer">
                                    <img src={MarriageRecords} alt="" />
                                    Marriage Records
                                </a>
                                <a className="menu-item" href="https://ourpublicrecords.org/dna-testing/" target="_blank" rel="noreferrer">
                                    <img src={DNATesting} alt="" />
                                    Dna Testing
                                </a>
                            </div>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </div>
    )
}

export default HeaderJsx;