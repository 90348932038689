import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { firstnameApi } from './services/fitstname'
import { lastnameApi } from './services/lastname'
import { firstnameLastnameApi } from './services/firstnameLastname'
import { filtersApi } from './services/filters'
import { utilsApi } from './services/utilsv2'

export const store = configureStore({
    reducer: {
        [filtersApi.reducerPath]: filtersApi.reducer,
        [firstnameApi.reducerPath]: firstnameApi.reducer,
        [lastnameApi.reducerPath]: lastnameApi.reducer,
        [firstnameLastnameApi.reducerPath]: firstnameLastnameApi.reducer,
        [utilsApi.reducerPath]: utilsApi.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            filtersApi.middleware,
            firstnameApi.middleware,
            lastnameApi.middleware,
            firstnameLastnameApi.middleware,
            utilsApi.middleware,
        ),
})

setupListeners(store.dispatch)