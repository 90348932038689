import HeaderJsx from '../components/HeaderJsx';
import DashboardFooter from '../components/DashboardFooter';
import Sticky from 'react-sticky-el';
import { Link as Link1, animateScroll as scroll } from "react-scroll";
import { Link } from 'react-router-dom';
import { useGetFirstnameByPrefixQuery } from '../../redux/services/fitstname';
import { useEffect, useState } from 'react';
import NamesBlock from '../components/NamesBlock'

const Names = () => {
    const AllPrefix = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    
    function numberWithCommas(x) {
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    function scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    }

    return (
        <div>
            <HeaderJsx />
                <div className="height-full-size explore-bg">
                    <div className="search-header">
                        <div className='conteiner'>
                            <Link to={`/`} onClick={scrollToTop}>Home</Link>
                            <span className="active"> / Names</span>
                        </div>
                    </div>
                    <div className='conteiner d-flex justify-content-between align-items-start py-5'>
                        
                        <Sticky className="conutry-content-header" boundaryElement="body">
                            <div className="conutry-content-header-nav conutry-content-header-nav-names scroll scroll-small border-none m-0 flex-column col-3">
                                {
                                    AllPrefix.map (content =>
                                        <Link1 to={content + "Scroll"}
                                            activeClass="active"
                                            spy={true}
                                            offset={-300}
                                            >
                                            {content}
                                        </Link1>
                                    )
                                }
                            </div>
                        </Sticky>
                        
                        <div className="col-9">
                            {
                                AllPrefix.map (content =>
                                    <NamesBlock latter={content} /> 
                                )    
                            }
                        </div>

                       

                    </div>
                </div>
            <DashboardFooter />
        </div>
    )
}

export default Names;