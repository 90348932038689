import React, { useEffect, useState } from "react";
import {
  LabelList,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell
} from "recharts";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { useGetFirstnameYearlyPopulationQuery } from "../../redux/services/fitstname";

const Timeline = () => {
  let params = useParams();
  let searchName = params.forename.charAt(0).toUpperCase() + params.forename.slice(1)
  const [maxBar, setMaxBar] = useState(0);
  
  const firstnameYearlyPopulation = useGetFirstnameYearlyPopulationQuery(searchName)


  let dataBarChart = [];
  let dataBarsChart = [];
  
  function numberWithCommas(x) {
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if(!firstnameYearlyPopulation.isLoading && !firstnameYearlyPopulation.isError) {
    for(let i = 0; i < firstnameYearlyPopulation.data.popularity.length; i++) {
      let yearlyPopulationData = {
        year: firstnameYearlyPopulation.data.years[i],
        NumberOfNames: firstnameYearlyPopulation.data.popularity[i],
        NumberFormatNames: firstnameYearlyPopulation.data.popularity[i]
      }
      dataBarChart.push(yearlyPopulationData)  
    }
    dataBarChart.sort(sortYear);

    function sortYear(a,b){
      if(a.year>b.year)return 1;
      if(a.year<b.year)return -1;
      return 0;
    }
    sortByDecade()
    function sortByDecade() {
      let deleteArray = []
      for(let i = 0; i < dataBarChart.length; i++) {
        var decadeNumber = (dataBarChart[i].year / 10);
        decadeNumber = parseInt(decadeNumber)
        
        if(i > 0) {
          var decadeNumberNext = (dataBarChart[i - 1].year / 10);
          decadeNumberNext = parseInt(decadeNumberNext)
          if(decadeNumber === decadeNumberNext) {
            dataBarChart[i].NumberOfNames = dataBarChart[i].NumberOfNames + dataBarChart[i - 1].NumberOfNames;
            let k = i - 1;
            deleteArray.push(k)
          }
        }
        dataBarChart[i].NumberFormatNames = new Intl.NumberFormat("en-US", {notation: "compact",compactDisplay: "short",}).format(dataBarChart[i].NumberOfNames)
      }
      for(let i = deleteArray.length - 1; i >= 0; i --) {
        dataBarChart.splice(deleteArray[i], 1)
      }
      for(let i = 0; i < dataBarChart.length; i++) {
        dataBarChart[i].year = parseInt(dataBarChart[i].year / 10) * 10;
      }
      dataBarsChart = dataBarChart;
    }
  }
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p className="custom-tooltip-label font-w-600">{`${label}`}</p>
            <p className="custom-tooltip-label">{`Number of Names : ${numberWithCommas(payload[0].value.toString())}`}</p>
          </div>
        );
      }
    
      return null;
    };


    
    if(dataBarChart.length !== 0) {
        for(let i = 0; i < dataBarChart.length; i++) {
            if(maxBar < dataBarChart[i].NumberOfNames) {
                setMaxBar(dataBarChart[i].NumberOfNames)
            }
        }
    }

    return (
            <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={dataBarChart}
                margin={{
                    left: 0
                }}
                >
                <CartesianGrid />
                <XAxis dataKey="year" stroke="#1E1E1E" fontSize={12} fontWeight={700}/>
                <YAxis stroke="#1E1E1E" fontSize={12} fontWeight={700} orientation='right'
                  tickFormatter={(value) =>
                    new Intl.NumberFormat("en-US", {
                      notation: "compact",
                      compactDisplay: "short",
                    }).format(value)
                  }
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar minPointSize={50} isAnimationActive={false} type="monotone" fill="#D6E3EB" dataKey="NumberOfNames" barSize={75}>
                <LabelList dataKey="NumberFormatNames" position="insideTop" style={{ fill: '#317199', fontWeight: '600', fontSize: "10px" }} offset={10}/>
                </Bar>
            </BarChart>
            </ResponsiveContainer>
    )
}

export default Timeline;