import HeaderJsx from '../components/HeaderJsx';
import DashboardFooter from '../components/DashboardFooter';
import { Link } from "react-router-dom";
import TotalNames from '../../assets/img/TotalNames.svg';
import Star from '../../assets/img/Star.svg';
import GlobalCommonality from '../../assets/img/GlobalCommonality.svg';
import LogoIcon from '../../assets/img/LogoIcon.svg';
import PublicRecords from '../../assets/img/PublicRecords.svg';
import Obituaries from '../../assets/img/Obituaries.svg';
import DeathRecords from '../../assets/img/DeathRecords.svg';
import BirthRecords from '../../assets/img/BirthRecords.svg';
import MarriageRecords from '../../assets/img/MarriageRecords.svg';
import DNATesting from '../../assets/img/DNATesting.svg';
import { useGetLastNameOfTheDayQuery, useGetMostPopularSurnamesQuery } from '../../redux/services/lastname';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Explore = () => {
    const mostPopularList = useGetMostPopularSurnamesQuery()
    const mostPopularName = useGetLastNameOfTheDayQuery()

    function numberWithCommas(x) {
        return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' })
    }, [])

    return (
        <div>
            <Helmet>
                <title>Explore the top 10 most frequently found surnames in the USA.</title>
                <meta
                    name="description"
                    content={`Our Public Records is a 100% free to access database of over 160000 names in the USA. Explore the most commonly found names here.`}
                />
            </Helmet>
            <HeaderJsx />
            <div className="height-full-size explore-bg">
                <div className="search-header">
                    <div style={{ display: 'flex', gap: '16px' }} className='conteiner'>
                        <Link to={`/`} onClick={scrollToTop}>Home</Link>
                        <span className="second-navigation-slash">/</span>
                        <span className="active">Explore</span>
                    </div>
                </div>
                <div className='conteiner d-flex justify-content-between align-items-start py-5 explore-data-mobile'>
                    {!mostPopularList.isLoading && !mostPopularList.isError
                        ?
                        <div className='popular-first-names'>
                            <h1 className='p-4 dashboard-border explore-title d-flex justify-content-between align-items-center'>
                                <span>Popular Last Names</span>
                                <span>Matching Records Found</span>
                            </h1>
                            {
                                mostPopularList.data.map((content, index) =>
                                    <div key={content.id} className='d-flex justify-content-between align-items-center p-4 w-100'>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <p className='mb-0 popular-first-index me-3'>{index + 1}.</p>
                                            <Link
                                                to={`/lastname/${content.value[0] + content.value.slice(1).toLowerCase()}`}
                                                onClick={scrollToTop} className='mb-0 popular-first-name'
                                            >
                                                {content.value[0] + content.value.slice(1).toLowerCase()}
                                            </Link>
                                        </div>
                                        <div className='popular-first-total-names'>
                                            <img src={TotalNames} alt="" />
                                            {numberWithCommas(content.amount_around_the_world.toString())}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        :
                        null
                    }

                    <div className='w-3 most-popular-today-mobile-explore'>
                        <div className='name-of-day p-4'>
                            <h3 style={{ width: 'fit-content' }}><img src={Star} alt="" /> Last name of the day</h3>
                            {!mostPopularName.isLoading && !mostPopularName.isError
                                ?
                                <>
                                    <Link
                                        to={`/lastname/${mostPopularName.data.value[0] + mostPopularName.data.value.slice(1).toLowerCase()}`}
                                        onClick={scrollToTop}
                                    >
                                        {mostPopularName.data.value[0] + mostPopularName.data.value.slice(1).toLowerCase()}
                                    </Link>
                                    <p className='name-of-day-des'>{mostPopularName.data.short_description}</p>

                                    <p className='name-of-day-small-title dashboard-border-top m-0 pt-3'><img src={TotalNames} alt="" /> Total Records Found</p>
                                    <p className='name-of-day-small-value dashboard-border pb-3 m-0'>{numberWithCommas(mostPopularName.data.amount_around_the_world.toString())}</p>

                                    <p className='name-of-day-small-title m-0 pt-3'><img src={GlobalCommonality} alt="" /> Worldwide Prevalence</p>
                                    <p className='name-of-day-small-value dashboard-border pb-3 m-0'>
                                        {numberWithCommas(mostPopularName.data.rating_position_worldwide.toString())}
                                        {mostPopularName.data.rating_position_worldwide % 10 === 1 && mostPopularName.data.rating_position_worldwide % 100 !== 11
                                            ? (
                                                <span>st</span>
                                            )
                                            : mostPopularName.data.rating_position_worldwide % 10 === 2 && mostPopularName.data.rating_position_worldwide % 100 !== 2 ? (
                                                <span>nd</span>
                                            )
                                                : mostPopularName.data.rating_position_worldwide % 10 === 3 && mostPopularName.data.rating_position_worldwide % 100 !== 13 ? (
                                                    <span>rd</span>
                                                ) :
                                                    <span>th</span>
                                        }
                                    </p>

                                    <p className='name-of-day-small-title m-0 pt-3'>Most Records Found In</p>
                                    <p className='name-of-day-small-value dashboard-border pb-3 m-0'>{mostPopularName.data.most_popular_country}</p>
                                </>
                                : null
                            }
                        </div>

                        <div className='name-of-day mt-4 bg-transparent name-of-day-block'>
                            <a
                                className="name-of-day-link"
                                href="https://ourpublicrecords.org/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={LogoIcon} alt="" style={{ "filter": "invert(39%) sepia(15%) saturate(3985%) hue-rotate(171deg) brightness(82%) contrast(69%)" }} />
                                Our Public Records
                            </a>

                            <a
                                className="name-of-day-link"
                                href="https://ourpublicrecords.org/public-records/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={PublicRecords} alt="" />
                                Public records
                            </a>

                            <a
                                className="name-of-day-link"
                                href="https://ourpublicrecords.org/obituaries/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={Obituaries} alt="" />
                                Obituaries
                            </a>

                            <a
                                className="name-of-day-link"
                                href="https://ourpublicrecords.org/death-records/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={DeathRecords} alt="" />
                                Death Records
                            </a>

                            <a
                                className="name-of-day-link"
                                href="https://ourpublicrecords.org/birth-records/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={BirthRecords} alt="" />
                                Birth Records
                            </a>

                            <a
                                className="name-of-day-link"
                                href="https://ourpublicrecords.org/marriage-records/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={MarriageRecords} alt="" />
                                Marriage Records
                            </a>

                            <a
                                className="name-of-day-link"
                                href="https://ourpublicrecords.org/dna-testing/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={DNATesting} alt="" />
                                Dna Testing
                            </a>
                        </div>

                    </div>

                </div>
            </div>
            <DashboardFooter />
        </div>
    )
}

export default Explore;