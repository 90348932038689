import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './index.css';
import App from './App';

import { store } from './redux/store'
import { Provider } from 'react-redux'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <title>OurPublicRecords - Free access to huge database of names</title>
        <meta
          name="description"
          content="OurPublicRecords is a 100% free to access huge database of names and genealogy information, along with history and statistics."
        />
      </Helmet>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
