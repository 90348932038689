import { useParams } from "react-router-dom";

import { useState } from 'react'
import { ComposableMap, Geographies, Geography, Annotation, ZoomableGroup, Sphere, Graticule } from "react-simple-maps"
import { scaleLog } from "d3-scale"
import ReCenter from '../../../assets/img/ReCenter.svg'
import FullScreen from '../../../assets/img/FullScreen.svg'
import { useGetFirstnamePopulationsQuery } from "../../../redux/services/fitstname";

const Map = ({ setTooltipContent, setTooltipContentPeple, setTooltipcontentRatio, setTooltipContentRank, setTooltipContentFlag }) => {
    
    let params = useParams();
    let searchName = params.forename.charAt(0).toUpperCase() + params.forename.slice(1)

    const [fullSize, setFullSize] = useState(true); 
    
    const firstnameDistribution = useGetFirstnamePopulationsQuery(searchName)
    
    const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"

    const colorScale = scaleLog().domain([1, 10000000]).range(["#e6f0ff", "#0066FF"]);
    const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });

    function handleZoomIn() {
        if (position.zoom >= 4) return;
        setPosition((pos) => ({ ...pos, zoom: pos.zoom * 1.5 }));
    }

    function handleZoomOut() {
        if (position.zoom <= 1) return;
        setPosition((pos) => ({ ...pos, zoom: pos.zoom / 1.5 }));
    }

    function handleZoomCenter() {
        setPosition((pos) => ({ coordinates: [0, 0], zoom: pos.zoom }));
    }

    function handleMoveEnd(position) {
        setPosition(position);
    }

    function handleOpenFull() {
        setFullSize(!fullSize)
        if(fullSize) {
            document.body.style.overflow = "hidden"
            document.getElementById("DistributionScroll").classList.add("map-active")
        }else {
            document.body.style.overflow = "auto"
            document.getElementById("DistributionScroll").classList.remove("map-active")
        }
    }
    if(!firstnameDistribution.isLoading && !firstnameDistribution.isError) 
    return (
        <div className='w-100 me-3 position-relative map-border' style={{"background": "#EBF1F5"}} data-tip="">
            <ComposableMap 
                width={800}
                height={500}
                style={{ width: "100%", height: "auto" }}>
                <ZoomableGroup
                    zoom={position.zoom}
                    center={position.coordinates}
                    onMoveStart={handleMoveEnd}
                >
                    <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                        geographies.map((geo, index) => {
                            const isos = firstnameDistribution.data.find((s) => s.country.country_code_iso3 === geo.id)
                            return (
                                <Geography
                                    key={index}
                                    geography={geo}
                                    fill={isos ? colorScale(isos["population"]) : "#b3d1ff"}
                                    className="focus-outline-none"
                                    stroke="#333333"
                                    strokeWidth="0.2"
                                    onMouseEnter={() => {
                                            setTooltipContent(`${geo.properties.name}`);
                                            if(isos !== undefined) {
                                                setTooltipContentPeple(isos.population)
                                                setTooltipcontentRatio(parseInt(isos.country.population / isos.population))
                                                setTooltipContentRank(isos.country.population)
                                                setTooltipContentFlag(isos.country.flag_link);
                                            }
                                    }}
                                    onMouseLeave={() => {
                                        setTooltipContent("");
                                        setTooltipContentPeple("");
                                        setTooltipcontentRatio("")
                                        setTooltipContentRank("")
                                        setTooltipContentFlag("")
                                    }}
                                    style={{
                                    hover: {
                                        fill: "#98B43B",
                                        outline: "none"
                                    },
                                    pressed: {
                                        fill: "#E42",
                                        outline: "none"
                                    }
                                    }}
                                />
                            )
                        })
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
            <div className="controls">
                <button onClick={handleZoomIn} className="button1 mb-1">
                    +
                </button>
                <button onClick={handleZoomOut} className="button1">
                    -
                </button>
            </div>
            <button onClick={handleZoomCenter} className="button3">
                <img
                    src={ReCenter}
                    alt="ReCenter"
                    />
                Re-Center
            </button>
            {/* <button className="button4" onClick={handleOpenFull}>
                <img
                    className=""
                    src={FullScreen}
                    alt="FullScreen"
                    />
            </button> */}
        </div>
    )
}

export default Map;